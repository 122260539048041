import $ from 'jquery';
import 'slimScroll';

export default function ScrollBox() {
}

ScrollBox.prototype = {
  init: function(size, parentSelector, scrollBoxSelector, optionalStaticExclude) {
    let $scrollBoxes = $(parentSelector),
      scrollBoxHeight = 0,
      excludedItemHeight = 0;

    $scrollBoxes.each(function() {
      excludedItemHeight = $(optionalStaticExclude, this).length > 0 ? $(optionalStaticExclude, this).outerHeight() : 0;
      scrollBoxHeight = size - excludedItemHeight;

      if ($(scrollBoxSelector, this).length > 0) {
        $(scrollBoxSelector, this).slimScroll({
          height: scrollBoxHeight + 'px',
          size: '10px',
          allowPageScroll: true
        });
      }
    });
  }
};
