import $ from 'jquery';
import Pubsub from 'pubsub';
import '../utils/forms';
import '../config/config';
import Loading from '../components/loading';


export default function Account() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
  this.loading = new Loading();
}

Account.prototype = {
  attach: function(msg, element) {
    var that = this;

    this.correctSliders = function() { //compensate for widths with decimal fractions in sliders
      var w = $(".slider-container").width();
      var hw = Math.floor(w / 2) + (w % 2);
      $(".toggle.handle").width(hw);
      $(".toggle.no").width(w - hw - 2);
      var yesToggles = $(".toggle.yes").width(w - hw - 2);
      //reset toggle position as well in resize!
      $(".slider").each(function(i) {
        if ((this.style.left !== "") && (parseInt(this.style.left) !== 0)) { //the slider is in the left position
          this.style.left = "-" + yesToggles.get(i).style.width;
        }
      });
    };
    $(window).off("resize").on("resize", function() { //corrects a bug that occurs of the overall width of the slider
      // container is odd (one pixel line of the red slider appears if slider is in 0 position)
      that.correctSliders();
    });
    this.correctSliders(); //correct the initial slider size set by the css
    //move the curtain on the menu
    $(".account-menu-up, .account-menu-handle").off("click").on("click", function(e) {
      that.curtain($(".account-menu-up .glyphicon-chevron-up").length > 0);
    });

    $(".account-preferences .toggle").off("click").on("click", function(e) {
      //get the parameter (aka, newsletter, opinion & info)
      var patty = /(\w+)$/;
      var result = patty.exec(this.parentNode.parentNode.parentNode.className);
      var parameter = result[result.length - 1]; //there should always be a result as long as the class name is not empty

      var slider = $(".account-preferences." + parameter + " .slider");
      var left = slider.get(0).style.left;
      var on = (left === "") || (parseInt(left) === 0); //old status
      var yes = !on;

      var width = $(".account-preferences." + parameter + " .yes").width();
      var delta = yes ? 0 : "-" + width;
      slider.animate({
        left: (delta + "px")
      });

      $("input[name='" + parameter + "']").get(0).value = !on ? 1 : 0;
    });
    $(".account-address-error-radio-col input[type='radio']").on("change", function() {
      $(".account-address-correction-row").toggleClass("selected", false);
      $(this.parentNode.parentNode).toggleClass("selected", true);
    });
    $(".account-address-correction-row").off("click").on("click", function() {
      //console.log("bg clicked");
      $(".account-address-correction-row").toggleClass("selected", false); //deselect all classes before selecting a new one
      $(this).toggleClass("selected", true);
      //console.log($(this).find("input[type='radio']"));
      $(this).find("input[type='radio']").get(0).checked = true;
    });

    var accordions = [".telnumber-edit", ".faxnumber-edit", ".email-edit", ".password-edit", ".iban-edit",
      "#account-address-edit-dialog", "#account-address-delete-confirmation", "#account-order-cancel-confirmation"];
    accordions = accordions.join(", ");
    //hides or shows all the command centers ("ändern" / "löschen") if one accordion is active
    $("#account-address-edit-dialog").on('hide.bs.collapse', function() {
      $(".command-center").toggleClass("disabled", false);
    }); //css("display", "")});
    $("#account-address-delete-confirmation").on('hide.bs.collapse', function() {
      $(".command-center").toggleClass("disabled", false);
    }); //css("display", "")});

    // eslint-disable-next-line complexity
    $(".account-edit-button .action-btn").off("click").on("click", function() {
      var form = $(this).closest("form");
      var target = this.getAttribute("data-target");
      if (target === '.email-edit') {
        $('#accountTargetType').val('EMAIL');
      } else if (target === '.password-edit') {
        $('#accountTargetType').val('PASSWORD');
      } else if (target === '.telnumber-edit') {
        $('#accountTargetType').val('PHONES');
      } else if (target === '.faxnumber-edit') {
        $('#accountTargetType').val('FAX');
      } else if (target === '.iban-edit') {
        $('#accountTargetType').val('BANKACCOUNT');
      }
      $.post(form.attr("action"), form.serialize(), function(data) {
        if (data.success) {
          // hide form and replace data
        } else {
          // replace form from data.markup
        }
      }).fail(function(jqXhr, e) {});
    });

    $(accordions).on('hide.bs.collapse', function() {
      $(".command-center").toggleClass("disabled", false);
    }); //css("display", "")});
    this.hideCaller(".account-data-edit-link");
    this.recycleDialog(".account-address-edit-link", "#account-address-edit-dialog");
    this.recycleDialog(".account-address-delete-link", "#account-address-delete-confirmation");
    this.recycleDialog(".account-order-cancel-link", "#account-order-cancel-confirmation");
  },
  //hides the link that triggered an event, as long as that link is a direct child of .command-center
  hideCaller: function(linkSelector) {
    //console.log("hideCaller " + linkSelector);
    $(linkSelector).off('click').on('click', function() {
      if ($(this.parentNode).is(".command-center")) {
        $(this.parentNode).toggleClass("disabled", true);
      } //css("display", "none")} //if the object's parent is already the command-center
    });
  },
  recycleDialog: function(linkSelector, dialogSelector) {
    //uses one dialog to be shown in an accordion behind one row out of an iteration of rows
    //gives each item in linkSelector the id linkSelector-99, where 99 designates the index of the DOM element in the iteration
    //if data-alias is defined, then the search for the row parent will be started at the DOM object
    // described by the selector in data-alias
    var links = $(linkSelector);
    // eslint-disable-next-line complexity
    links.off('click').on('click', function() {
      //let the commands disappear while working on this; only functions correctly if it is the immediate parent;
      // for the case further upstream, jquery's parentsUntil() is not really a solution, since it delivers an array
      // of DOM-objects, even if there is no proper result
      if ($(this.parentNode).is(".command-center")) {
        $(this.parentNode).toggleClass("disabled", true);
      } //css("display", "none")} //if the object's parent is already the command-center

      var target = this.getAttribute("href") || this.getAttribute("data-target");
      var isOpen = $(target).is(".in");
      //console.log("target " + target + (isOpen ? " is open" : " is closed"));
      if (isOpen) {
        $(target).collapse("hide");
      //alert("bla");
      } //currently this event is blocked concurrently by the default event

      //find the div.row that sits upstream from this link; jquery's parentsUntil() has a bug
      // (aka, if no result, returns all parents until the html-object
      var alias = this.getAttribute("data-alias");
      var Node = alias ? $(alias).get(0) : this;
      var parentNode = $(Node).is(".row, .form-group") ? Node : Node.parentNode;
      while (parentNode && !$(parentNode).is(".row, .form-group")) {
        parentNode = parentNode.parentNode;
      }
      if ($(parentNode).is(".row, .form-group")) {
        //console.dir(parentNode);
        $(parentNode).after($(dialogSelector));
      }
    });
    linkSelector = linkSelector.replace(/^\W/, "");
    for (var i = 0; i < links.length; i++) { //give each edit link a unique id,
      // so that it has a pseudo-index with which the edit for can be placed
      links[i].id = linkSelector + "-" + i;
    }
  },
  curtain: function(up) { //draws the curtain for the local menu up or down
    var h = $(".account-menu").height();
    $(".local-menu-sm").animate({
      height: up ? 65 : (h + 40)
    }, {
      step: function() {
        var delta = -((h + 20) - $(this).height());
        //        console.log(delta);
        $(".account-menu").css("top", delta + "px");
      }
    });
    //console.log("height " + (up ? (h-20) : 28));
    var glyphicon = $(".account-menu-up .glyphicon");
    glyphicon.toggleClass("glyphicon-chevron-up", !up);
    glyphicon.toggleClass("glyphicon-chevron-down", up);
  },
  localmenu: function(sm, on_off) { //attaches the local menu either to the div relevant for the desktop location,
    // or the tab/smartphone location
    var lmSM = $(".local-menu-sm").get(0);
    var mdSM = $(".local-menu-md").get(0);
    var localMenu = $(".local-menu").get(0);

    if (sm && on_off) {
      $(lmSM).css("display", "block");
      $(lmSM).css("height", "");
      lmSM.appendChild(localMenu.parentNode.removeChild(localMenu));
    }
    else if (!sm && on_off) {
      $(lmSM).css("display", "none");
      mdSM.appendChild(localMenu.parentNode.removeChild(localMenu));
    }

  },
  // eslint-disable-next-line complexity
  responsive: function(msg, on_off) {
    this.localmenu(msg.match(/\.(xs|sm)$/), on_off);
    switch (msg) {
    case 'res.xs':
      if (on_off) { // entered state xs
      } else { // left state xs

      }
      break;
    case 'res.sm':
      //this.curtain(false);
      if (on_off) { // entered state sm
      } else { // left state sm

      }
      break;
    default:
      this.curtain(false);
      //eslint-disable-next-line no-empty
      if (on_off) {
      }
    }
  }
};
