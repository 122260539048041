let shift = function (slide) {
  if (slide.getAttribute("data-hotspot")) {
    let hotSpotX = Math.ceil(slide.getAttribute("data-hotspot-x") / 1.5);
    let maxOffset = slide.width - window.innerWidth;
    let offset = Math.min(hotSpotX, maxOffset);
    slide.style.transform = "translateX(-" + offset + "px)";
  }
};

let hotspotSlides = document.querySelectorAll(".hotspot-slide");

for (let slide of hotspotSlides) {
  shift(slide);
}
