import $ from 'jquery';
import '../../libs/jquery.validate';
import '../../libs/additional-methods';
$.extend($.validator.messages,
  {
  required: typeof window.errorRequired !== 'undefined' ? window.errorRequired : "Bitte füllen Sie dieses Feld aus.",
  remote: typeof window.errorFix !== 'undefined' ? window.errorFix : "Bitte berichtigen Sie dieses Feld.",
  email: typeof window.errorEmail !== 'undefined' ? window.errorEmail : "Bitte geben Sie eine gültige Email ein.",
  url: typeof window.errorUrl !== 'undefined' ? window.errorUrl : "Bitte geben Sie eine gültige URL an.",
  date: typeof window.errorDate !== 'undefined' ? window.errorDate : "Bitte geben Sie ein gültiges Datum an",
  dateISO: typeof window.errorDateIso !== 'undefined' ? window.errorDateIso : "Bitte geben Sie ein gültiges (ISO) Datum an.",
  number: typeof window.errorPhone !== 'undefined' ? window.errorPhone : "Bitte geben Sie eine gültige Nummer an",
  digits: typeof window.errorDigits !== 'undefined' ? window.errorDigits : "Bitte geben sie nur Zahlen ein",
  equalTo: typeof window.errorEqual !== 'undefined' ? window.errorEqual : "Die Werte müssen identisch sein.",
  maxlength: typeof window.errorMaxLength !== 'undefined'
    ? $.validator.format( window.errorMaxLength)
    : $.validator.format("Bitte geben Sie maxixaml {0} Zeichen ein."),
  minlength: typeof window.errorMinLength !== 'undefined'
    ? $.validator.format( window.errorMinLength)
    : $.validator.format("Bitte geben Sie minimal {0} Zeichen ein."),
  rangelength: typeof window.errorRangeLength !== 'undefined'
    ? $.validator.format( window.errorRangeLength )
    : $.validator.format("Bitte geben Sie einen Wert mit {0} bis {1} Zeichen ein."),
  range: typeof window.errorRange !== 'undefined'
    ? $.validator.format( window.errorRange )
    : $.validator.format("Bitte geben Sie einen Wert mit {0} bis {1} Zeichen ein."),
  max: typeof window.errorMax !== 'undefined'
    ? $.validator.format( window.errorMax )
    : $.validator.format("Bitte geben Sie einen Wert mit {0} oder weniger Zeichen ein."),
  min: typeof window.errorMin !== 'undefined'
    ? $.validator.format( window.errorMin )
    : $.validator.format("Bitte geben Sie einen Wert mit {0} oder mehr Zeichen ein."),
  iban: typeof window.errorIban !== 'undefined'
    ? window.errorIban : "Bitte geben Sie eine gültige IBAN an.",
  bic: typeof window.errorBic !== 'undefined'
    ? window.errorBic
    : "Bitte geben Sie eine gültige BIC ein.",
    pattern: typeof window.errorPattern !== 'undefined' ? window.errorPattern : "Es sind nur Zeichen erlaubt."
});
const SepaDirectDebit = {
  validator: $('#paymentBaseForm').validate({
    rules: {
      iban: {
        required: true,
        iban: true
      },
      bic: {
        required: true,
        bic: true
      },
      firstName: {
        required: true,
        minlength: 2,
        maxlength: 15,
        pattern: "^[^0-9]+$"
      },
      lastName: {
        required: true,
        minlength: 2,
        maxlength: 15,
        pattern: "^[^0-9]+$"
      },
      disclaimer: {
        required: true
      }
    },
    errorClass:"sepa-val-error",
    errorPlacement: function(error, element) {
      if (element.attr('name') === 'disclaimer') {
        $(".msg").text(error.html());
      } else {
        error.insertAfter(element);
      }
    },
    success: function (error) {
      error.remove();
    }

  }),
  validateForm: function() {
    return this.validator.form();
  }
};
export default SepaDirectDebit;

