export default function GTMHelper() {
}

GTMHelper.prototype = {
  pushEvent: function (data) {
    if (!data || !window.dataLayer) {
      return;
    }
    if (!Array.isArray(data)) {
      data = [data];
    }
    for (let i of data) {
      window.dataLayer.push(i);
    }
  },

  trackCheckoutTrackingContainer: function (step, option) {
    this.pushEvent(this.getTrackingEventCheckoutContainer(step, option));
  },

  trackCheckoutOptionTrackingContainer: function (step, option) {
    this.pushEvent(this.getTrackingEventCheckoutOptionContainer(step, option));
  },

  getTrackingEventCheckoutContainer: function (step, option) {
    return {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step: step,
            option: option
          }
        }
      }
    };
  },

  getTrackingEventCheckoutOptionContainer: function (step, option) {
    return {
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField: {
            step: step,
            option: option
          }
        }
      }
    };
  }
};
