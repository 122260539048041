import $ from 'jquery';
import Pubsub from 'pubsub';

export default function ProductBox() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

ProductBox.prototype = {

  attach: function () {
    const sizeVariations = $(".thumbnail-sizes a");
    const colorList = $(".box-color-list .valid a.color-list-variation");
    let that = this;
    sizeVariations.on({
      "mouseover": function () {
        that.selectedBoxSizing = $(this);
        clearTimeout(that.hoverDelay);
        that.hoverDelay = setTimeout(function () {
          that.showVariationSizes();
        }, 100);
      },
      "mouseout": function () {
        clearTimeout(that.hoverDelay);
        that.hideVariationSizes();
      },
      "click": function (e) {
        e.preventDefault();
        that.selectedBoxSizing = $(this);
        that.showVariationSizes();
      }
    });

    colorList.on({
      "mouseover": function () {
        that.selectedColorspot = $(this);
        clearTimeout(that.hoverDelay);
        that.hoverDelay = setTimeout(function () {
          that.changeActiveColorSpot();
        }, 100);
      },
      "mouseout": function () {
        clearTimeout(that.hoverDelay);
      },
      "click": function (e) {
        e.preventDefault();
        that.selectedColorspot = $(this);
        that.changeActiveColorSpot();
      }
    });
  },

  showVariationSizes: function () {
    let thumbnail = this.selectedBoxSizing.closest('div[class="thumbnail"]');
    thumbnail.find("div.thumbnail-sizes-overlay").each(function () {
      $(this).addClass("active");
    });
    thumbnail.find("div.collectable").each(function () {
      $(this).addClass("hidden");
    });
  },

  hideVariationSizes: function () {
    if (typeof this.selectedBoxSizing !== "undefined") {
      let thumbnail = this.selectedBoxSizing.closest('div[class="thumbnail"]');
      thumbnail.find("div.thumbnail-sizes-overlay").each(function () {
        $(this).removeClass("active");
      });
      thumbnail.find("div.collectable").each(function () {
        $(this).removeClass("hidden");
      });
    }
  },

  changeActiveColorSpot: function () {
    if (!this.selectedColorspot.hasClass("active")) {
      this.selectedBoxSizing = $("a", this.selectedColorspot.closest('div[class="thumbnail"]'));
      let colorList = this.selectedColorspot.closest('div[class^="box-color-list"]');
      colorList.find("a.active").each(function () {
        $(this).removeClass("active");
      });
      this.selectedColorspot.addClass("active");
      this.changeProductBoxLinks(this.selectedColorspot);
      this.loadAndChangeImage(this.selectedColorspot);
    }
  },

  changeProductBoxLinks: function () {
    let itemContainer = this.selectedColorspot.closest('div[class^="prd-grid-item-container"]');
    let newLink = this.selectedColorspot.data('item-url');
    itemContainer.find("a.item-link").each(function () {
      $(this).attr("href", newLink);
    });
  },

  loadAndChangeImage: function () {
    let that = this;
    let linkValue = this.selectedColorspot.data("link-value");
    let colorClasses = "." + linkValue.toString().split(" ").join(".");
    let itemContainer = this.selectedColorspot.closest('div[class^="prd-grid-item-container"]');
    let oldImages = $("div.box-image:not(.inactive):not(" + colorClasses + ")", itemContainer);
    let newImages = $("div.box-image.inactive" + colorClasses, itemContainer);
    let oldSizingLink = $("div.thumbnail-sizes:not(" + colorClasses + ")", itemContainer);
    let newSizingLink = $("div.thumbnail-sizes" + colorClasses, itemContainer);

    oldSizingLink.each(function () {
      $(this).removeClass("active");
    });
    newSizingLink.each(function () {
      $(this).addClass("active");
    });

    newImages.each(function () {
      let newImageContainer = $(this);
      $("img.lazy-color", newImageContainer).each(function () {
        let newImage = $(this);
        if (!newImage.hasClass("initialized")) {
          newImage.on("load", function () {
            newImage.addClass("initialized");
          });
          newImage.on("error", function () {
            if (!newImage.hasClass("thumbnail-no-img")) {
              newImage.attr("src", newImage.attr("data-src-fallback"));
            }
            newImage.addClass("initialized thumbnail-no-img");
          });
          newImage.attr("src", $(this).attr("data-src"));
        }
        that.changeImage(newImageContainer, oldImages);
      });
    });
  },

  changeImage: function (newImageContainer, oldImages) {
    this.hideVariationSizes();
    const inactiveClass = "inactive";
    newImageContainer.removeClass(inactiveClass);
    oldImages.each(function () {
      $(this).addClass(inactiveClass);
    });
  }

};
