import $ from "jquery";
import Pubsub from "pubsub";
import {Validator} from "../utils/forms";
import config from "../config/config";
import Loading from "../components/loading";
import * as Urls from "../utils/urls";
import CartInput from "../components/cart_input";
import log from "consolelog";
import Html5Validation from '../utils/html5validation';
import "../utils/form_elements/plusminus";
import "bootstrap";

const loading = new Loading();
const cartInput = new CartInput();
const notepadInput = new CartInput(".notepad-header");

export default function Cart() {
  Pubsub.subscribe('core.attach', attach);
  Pubsub.subscribe('res', responsive);
}

function attach(msg, element) {
  $(".count-element", element).plusminus();

  $("#reservationSumbitBtn", element).off("click").on("click", function () {
    $(this).attr("disabled", "disabled");
  });

  var html5validation = new Html5Validation();
  html5validation.addValidationHandler();
  html5validation.validateOnSubmit();

  $(".voucher-toggle").off("click").click(function () {
    $(this).toggleClass("toggled");
    $(this).parent().parent().find(".voucher-input").toggleClass("hidden");
  });

  if ($(element).is(".cart-edit")) {
    const cartEdit = element;

    cartEdit.bind("hidden.bs.collapse", function () {
      $(this).remove();
    });
    cartEdit.collapse({
      toggle: false
    });

    $("[data-update]", cartEdit).on("change", function () {
      loading.load();
      $.get(Urls.make(config.basketEditPath, $("form", cartEdit).serialize()), function (data) {
        const $data = $(data);
        cartEdit.replaceWith($data);

        loading.stop();

        //Attach event handlers
        Pubsub.publish("core.attach", $data);
        $data.addClass("in");
      }).fail(function (jqXhr, e) {
        loading.stop();
        log(e);
      });
    });

    //Submit edit form
    $("button.edit", element).off("click").on("click", function (e) {
      const form = $(this).closest("form");
      const validator = new Validator(form);
      const $editErrElem = $('#edit-err-msg');
      e.preventDefault();
      $editErrElem.hide();
      $editErrElem.html('');

      if (!validator.validate()) {
        return;
      }

      const $editButton = $(this);
      $.post(form.attr("action"), form.serialize(), function (data) {
        if (data.success) {
          const $cartEdit = $(".cart-edit");
          $cartEdit.collapse("hide");
          const $product = $cartEdit.closest(".cart-product");

          $("body, html").animate({
            scrollTop: $product.offset().top - 100 + "px"
          }, 200, function () {
            if (data.warningMessages && data.warningMessages.length > 0) {
              const $cartProduct = $editButton.parent(".cart-product");
              log("show warning messages: ", data.warningMessages, $cartProduct);
              // TODO pop out message
            }
            setTimeout(function () {
              window.location.reload(true);
            }, 1000);
          });
        } else if (data.warningMessages && data.warningMessages.length > 0) {
          $editErrElem.html(data.warningMessages[0]);
          $editErrElem.show();
        }
      }).fail(function (jqXhr, e) {
        log(e);
      });
    });
  }

  //Edit Form
  $(".actions .edit a").off("click").on("click", function (e) {
    e.preventDefault();

    const $this = $(this);
    if ($this.hasClass("loading")) {
      return;
    }

    const $cartProduct = $this.closest(".cart-product");

    const $cartEdit = $cartProduct.find(".cart-edit");
    if ($cartEdit.length > 0) {
      $cartEdit.collapse("hide");
      return;
    }
    $(".cart-edit").not($cartEdit).collapse("hide");

    const url = Urls.make(config.basketEditPath, $.param({
      entryId: $(this).attr("data-entryId"),
      itemId: $(this).attr("data-itemId"),
      quantity: $(this).attr("data-quantity"),
      free: $(this).attr("data-free"),
      basketType: $(this).attr("data-basket-type")
    }));

    loading.load();

    //Load edit form
    $.get(url, function (data) {
      loading.stop();

      const $cartEdit = $(data);
      $cartProduct.find(".actions").before($cartEdit);

      //Attach event handlers
      Pubsub.publish("core.attach", $cartEdit);

      $cartEdit.collapse('show').on("shown.bs.collapse", function () {
        $("body, html").animate({
          scrollTop: $cartProduct.offset().top - 100
        }, 500);
      });
    }).fail(function (jqXhr, e) {
      loading.stop();
      log(e);
    });
  });

  $(".all-in-cart").off("click").on("click", function (e) {
    const $form = $("#all-in-cart-form");
    $.post($form.attr("action"), $form.serialize(), function (data) {
      cartInput.input(data.markup, data.count, data.total, null, null);
    }).fail(function (jqXhr, e) {
      log(e);
    });
  });

  $(".actions .to-cart a").off("click").on("click", function (e) {
    e.preventDefault();
    $.get($(this).attr("href"), function (data) {
      cartInput.input(data.markup, data.count, data.total, null, null);
    }).fail(function (jqXhr, e) {
      log("Add to card/notepad failed.", e);
    });
  });

  const wishlistPopup = $('#wishlist-popover');
  if (wishlistPopup.length !== 0) {
    const data = JSON.parse(wishlistPopup.html());
    notepadInput.input(data.markup, data.count, data.total);
  }

  $('input#order-count').change(function () {
    displayOrderCntErrMsg();
  });

  $('span.input-group-btn.minus').click(function () {
    displayOrderCntErrMsg();
  });
}

function displayOrderCntErrMsg() {
  let orderCountSelector = $('input#order-count');
  let orderCount = orderCountSelector.val();
  let itemStockQuantity = orderCountSelector.data('max');
  if (itemStockQuantity < orderCount) {
    $('#order-cnt-err-msg').css('display', 'block');
  } else {
    $('#order-cnt-err-msg').css('display', 'none');
  }
}

function responsive(msg, onOff) {
  if (!onOff) {
    return;
  }

  const $swipes = $(".offers .swipe, .sellers .swipe");

  $swipes.each(function () {
    if ($(this).data("Swipe")) {
      $(this).data("Swipe").kill();
    }
  });

  switch (msg) {
    case 'res.xs':
      $swipes.Swipe({
        pair: 2
      }).knobs().lazy();
      break;
    case 'res.sm':
      $swipes.Swipe({
        pair: 3
      }).knobs().lazy();
      break;
    default:
      $swipes.Swipe({
        pair: 4,
        continuous: false
      }).controls().lazy();
  }
}
