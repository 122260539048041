import $ from 'jquery';
import '../utils/lazyload';
import SliderInitializer from '../utils/slider-initializer';

export default function LazyScroll(elementSelector, options) {
  let defaults = {
    count: 60,
    throttle: 150
  };

  this.options = $.extend(defaults, options);
  this.elementSelector = elementSelector;

  this.setup();
}

LazyScroll.prototype = {
  sliderInitializer: new SliderInitializer(),

  setup: function () {
    let that = this,
    timeout = null;

    $(window).on("resize scroll", function () {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(function () {
        that.checkAndLoadVisibleElements();
      }, that.options.throttle);
    });

    this.checkAndLoadVisibleElements();
  },
  checkAndLoadVisibleElements: function () {
    let that = this,
        $elementsToLoad = $(that.elementSelector).not(".initialized"),
        count = 0;

    // eslint-disable-next-line consistent-return
    $elementsToLoad.each(function () {
      if (that.isElementInViewport(this)) {
        that.loadContent(this);

        $(this).addClass("initialized");
        count++;
      }

      if (count >= that.options.count) {
        return false;
      }
    });
  },
  isElementInViewport: function (el) {
    let rect = el.getBoundingClientRect();

    return (
        rect.bottom >= 0
        && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right >= 0
        && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  },
  loadContent: function (el) {
    let sliderSelector = this.sliderInitializer.sliderConfigs.productSliderConfig.selector,
        sliderConfig = this.sliderInitializer.sliderConfigs.productSliderConfig.config,
        $slider = $(sliderSelector, el);

    if ($slider.length === 1) {
      this.sliderInitializer.singleInit($slider, sliderConfig);
    } else {
      $(el).lazyload();
    }
  }
};
