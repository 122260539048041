import $ from 'jquery';
import config from '../config/config';
import CartInput from "../components/cart_input";
import Loading from "../components/loading";

export default function ShopTheLook() {
  this.init();
  this.cart_input = new CartInput();
  this.loading = new Loading();
}

ShopTheLook.prototype = {
  init: function () {

    this.addToBasketListener();
    this.addChangeVariationListener();
    this.addMultiAddItemsListener();

  },
  addChangeVariationListener: function () {
    var that = this;

    $("[data-update]").off("change").on("change", function () {
      if($(this).val() === "notSelected") {
        return;
      }

      that.loading.load();
      var variationInputField = $(this);
      var checkedStatus = [];
      $('input[name=addToBasket]').each(function () {
        checkedStatus.push(this.checked);
      });

      $.get(config.variationChangePath + "?q=pda_top&" + $(this).closest("#variation-form").serialize(), function (htmlData) {
        that.loading.stop();
        $(variationInputField).closest(".prd-grid-item").replaceWith(htmlData);
        that.addChangeVariationListener();
        var checkStatusLength = checkedStatus.length;
        var iteration = checkStatusLength;
        $('input[name=addToBasket]').each(function () {
          var arrayPostion = checkStatusLength - iteration;
          this.checked = checkedStatus[arrayPostion];
          if (this.checked) {
            $(this).closest(".prd-grid-item-container").addClass("selected");
          }
          iteration--;
        });
        that.addToBasketListener();
        showOrHideMultiAddButton();
      }).fail(function (jqXhr, e) {
      });

    });

    $("[data-update]").mousedown(function () {
      $(this).removeClass("has-error");
    });

  },

  addMultiAddItemsListener: function () {
    var that = this;

    $("#multiAddItems").on("click", function () {
      var notSelectedVariations = false;

      $(".prd-grid-item-container.selected").find("[data-update]").each(function () {
        if($(this).val() === "notSelected") {
          $(this).addClass("has-error");
          notSelectedVariations = true;
        }
      });

      if(notSelectedVariations) {
        return;
      }

      var url = createMultiAddUrl();
      $.get(url, function (jsonData) {
        that.cart_input.input(jsonData.markup, jsonData.count, null, null, null, null, true, jsonData.basketUrl);
      }).fail(function (jqXhr, e) {
      });

      resetSelection();
    });
  },

  addToBasketListener: function () {
    $('input[name=addToBasket]').change(function () {
      if (this.checked) {
        $(this).closest(".prd-grid-item-container").addClass("selected");
      } else {
        $(this).closest(".prd-grid-item-container").removeClass("selected");
      }
      showOrHideMultiAddButton();
      $(".cart-header").popover("hide").popover("destroy");
    });
  }
};


function createMultiAddUrl() {
  var url = config.multiAddItemsPath;

  $('input[name=addToBasket]').each(function () {
    if (this.checked) {
      let currentItemId = $(this).closest("#variation-form").find("input[name=itemId]").val();

      if (!url.endsWith("?")) {
        url += "&";
      }
      url += $.param({itemId: currentItemId});
    }
  });
  return url;
}

function showOrHideMultiAddButton() {
  //check if at least one product is selected for add-to-basket
  let itemsSelected = false;
  $('input[name=addToBasket]').each(function () {
    itemsSelected = itemsSelected || this.checked;
  });

  if (itemsSelected) {
    $(".multi-add-items").slideDown("fast");
  } else {
    $(".multi-add-items").slideUp("fast");
  }
}

function resetSelection() {
  $(".prd-grid-item-container").removeClass("selected");
  $("input[name=addToBasket]").prop("checked", false);
  $(".multi-add-items").slideUp("fast");
}


