import $ from 'jquery';

$.fn.lazyload = function () {
  let $contentToSearchFor = $(this),
    lazyElements = [];

  $("img.lazy[data-src]:not(.loaded)", $contentToSearchFor).each(function () {
    $(this).on("load", function () {
      $(this).addClass("loaded");
    });


    $(this).attr("src", $(this).attr("data-src"));

    lazyElements.push(this);
  });

  return lazyElements;
};
