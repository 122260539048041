/**
 * Form Validation
 * Regex taken from https://github.com/jzaefferer/jquery-validation/
 * validation rules are taken from input types and data-attributes
 **/
import $ from 'jquery';

export function Methods() {
  // body...
}

Methods.prototype = {
  // http://docs.jquery.com/Plugins/Validation/Methods/required
  required: function(value, element) {
    if (element.nodeName.toLowerCase() === "select") {
      // could be an array for select-multiple or a string, both are fine this way
      var val = $(element).val();
      return val && val.length > 0;
    }

    if (this.checkable(element)) {
      return this.getLength(value, element) > 0;
    }
    return $.trim(value).length > 0;
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/email
  email: function(value) {
    // contributed by Scott Gonzalez: http://projects.scottsplayground.com/email_address_validation/
    // eslint-disable-next-line no-control-regex,max-len
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(value);
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/url
  url: function(value) {
    // contributed by Scott Gonzalez: http://projects.scottsplayground.com/iri/
    // eslint-disable-next-line max-len
    return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/date
  date: function(value) {
    return !/Invalid|NaN/.test(new Date(value).toString());
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/dateISO
  dateISO: function(value) {
    return /^\d{4}[\/\-]\d{1,2}[\/\-]\d{1,2}$/.test(value);
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/number
  number: function(value) {
    return /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value);
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/digits
  digits: function(value) {
    return /^\d+$/.test(value);
  },

  // http://docs.jquery.com/Plugins/Validation/Methods/creditcard
  // based on http://en.wikipedia.org/wiki/Luhn
  creditcard: function(value) {
    // accept only spaces, digits and dashes
    if (/[^0-9 \-]+/.test(value)) {
      return false;
    }
    var nCheck = 0,
      nDigit = 0,
      bEven = false;

    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n);
      nDigit = parseInt(cDigit, 10);
      if (bEven) {
        if ((nDigit *= 2) > 9) {
          nDigit -= 9;
        }
      }
      nCheck += nDigit;
      bEven = !bEven;
    }

    return (nCheck % 10) === 0;
  },

  minlength: function(value, param) {
    var length = value.length;
    return length >= param;
  },

  maxlength: function(value, param) {
    var length = value.length;
    return length <= param;
  },

  rangelength: function(value, param) {
    var length = value.length;
    return (length >= param[0] && length <= param[1]);
  },

  min: function(value, param) {
    return value >= param;
  },

  max: function(value, param) {
    return value <= param;
  },

  range: function(value, min, max) {
    return (this.min(min) && this.max(max));
  },
  checkable: function(element) {
    return (/radio|checkbox/i).test(element.type);
  },
  getLength: function(value, element) {
    switch (element.nodeName.toLowerCase()) {
    case "select":
      return $("option:selected", element).length;
    case "input":
      if (this.checkable(element)) {
        return this.findByName(element.name).filter(":checked").length;
      }
    }
    return value.length;
  },
  findByName: function(name) {
    return this.$el.find("[name='" + name + "']");
  }
};

export function Validator($el, options) {
  this.$el = $el;
  this.methods = new Methods();
  this.scrollToError = false;

  var that = this;

  this.setElements();
  this.formElements.off("change.formvalidation").on("change.formvalidation", function(e) {
    that.validateElement($(this), true);
  });

  if (options && options.plugin) {

    var submitListener = function(e) {
      if (!that.validate(true)) {
        e.preventDefault();
        e.stopPropagation();

      }
    };
    if (this.$el.is('form')) {
      if (!this.$el.is("[data-ajax]")) {
        this.$el.submit(submitListener);
      }
    }
    else {
      this.validate(true);
    }
  }
}

Validator.prototype = {
  //classes argument determines, if error css classes are set or not
  validate: function(classes, noerror) {
    this.setElements();

    var that = this;


    var ok = true;
    this.formElements.each(function() {
      var elementOk = that.validateElement($(this), classes);
      if (!elementOk) {
        ok = elementOk;
        that.showError($(this));
      }
    //return ok;
    });

    that.$el.data("FormValid", ok);
    if (!ok && !noerror) {
      this.showError(this.$el);
    }
    return ok;
  },
  showError: function($element, keepError) {
    $(".form-error", $element).first().show();
    var msg = $(".form-error", $element).first().attr("data-orig-error");
    if (msg && !keepError) {
      $(".form-error .msg", $element).first().html(msg);
    }
    if (this.scrollToError) {
      var offset = ($(".form-error", $element).length > 0)
          ? $(".form-error", $element).first().offset().top
          : $element.offset().top;
      $("html, body").animate({
        scrollTop: offset
      }, 200);
    }
  },
  // eslint-disable-next-line complexity
  validateElement: function($el, classes) {
    var val = $el.val();
    var ok = true;

    if ($el.attr("required") != null || val !== "") {
      if ($el.attr("required") && !this.methods.required(val, $el[0])) {
        ok = false;
      } else if ($el.attr("type") === "url" && !this.methods.url(val)) {
        ok = false;
      } else if ($el.attr("data-min") && (!this.methods.digits(val) || !this.methods.min(val, parseInt($el.attr("data-min"))))) {
        ok = false;
      } else if ($el.attr("data-max") && (!this.methods.digits(val) || !this.methods.max(val, parseInt($el.attr("data-max"))))) {
        ok = false;
      } else if ($el.attr("data-date") != null && $el.attr("data-agerange")) {
        var agerange = $el.attr("data-agerange").split(";");
        var min = agerange[0];
        var max = agerange[1];
        val = this.jsDateString(val);

        if (this.methods.date(val)) {
          const age = this.getAge(val);
          // eslint-disable-next-line max-depth
          if (age < min || age > max) {
            ok = false;
          }
        } else {
          ok = false;
        }
      } else if ($el.attr("data-date") && !this.methods.date(this.jsDateString(val))) {
        ok = false;
      } else if ($el.attr("data-match") && $($el.attr("data-match")).val() !== val) {
        ok = false;
      }
      if ($el.is("[type=radio]:checked") && $el.prop("disabled")) {
        ok = false;
      }
    }

    if (!ok && classes) {
      $el.parent().addClass("has-error");
    }
    else if (classes) {
      $el.parent().removeClass("has-error");
    }
    return ok;
  },
  setElements: function() {
    this.formElements = this.$el.find("input, select, textarea")
      .not(":submit, :reset, :image");
  },
  jsDateString: function(str) {
    var pieces = str.split(".");
    return pieces[1] + "/" + pieces[0] + "/" + pieces[2];
  },
  getAge: function(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
};

$.fn.validator = function(options) {
  var matched = $(this);

  matched.each(function() {
    options = $.extend(options, {
      plugin: true
    });
    new Validator($(this), options); // eslint-disable-line no-new
  });
  return matched;
};
