import $ from 'jquery';
import Pubsub from 'pubsub';

export default function StickyFooter() {
}

StickyFooter.prototype = {
  minHeightElemSelector: '.js_main-content-wrapper',
  $minHeightElem: $(),
  elementsForCalculation: [
    '.nav-meta-wrapper',
    '.main-header',
    '.breadcrumb-wrapper',
    '.footer-links__wrapper',
    '.main-footer'
  ],
  timeout: 0,

  init: function() {
    // sticky-footer js version for all pages without css sticky-footer
    if ($('html:not(.sticky-footer)').length > 0) {
      Pubsub.subscribe('res', $.proxy(this.breakpointChange, this));
      this.$minHeightElem = $(this.minHeightElemSelector);
    }
  },

  calculateAndSetMinHeight: function() {
    this.$minHeightElem.css('min-height', 250);
    $('body').removeClass('bp-changed');
  },

  breakpointChange(res, on_off) {
    let that = this;

    if (on_off) {
      $('body').addClass('bp-changed');
      clearTimeout(this.timeout);
      this.timeout = setTimeout(that.calculateAndSetMinHeight(), 300);
    }
  }
};
