/**
* jQuery Plugin for swipejs, adding lazy load to images having the data-src attribute
**/
import $ from 'jquery';

function Lazy($el) {
  this.slider = $el.data("Swipe");
  this.$el = $el;
  this.init();
  this.$el.bind("swipe.kill", $.proxy(this.kill, this));
}

Lazy.prototype = {
  init: function() {
    if (!this.slider) {
      return;
    }

    this.numSlides = this.slider.getNumSlides();
    this.pos = this.slider.getPos();

    this.originalCallback = this.slider.options.callback;
    this.slider.options.callback = $.proxy(this.callback, this);

    this.callback(this.pos);
  },
  kill: function() {
    this.$el.unbind("swipe.kill");
  },
  callback: function(pos) {
    if (this.originalCallback) {
      this.originalCallback(pos);
    }

    if (this.numSlides === 2) {
      this.loadImageAtPos(pos);
      this.loadImageAtPos(pos + 2);
    }
    else {
      this.loadImageAtPos(pos);
    }
  },
  loadImageAtPos: function(pos) {
    $(".swipe-wrap > div", this.$el).eq(pos).find("img[data-src]").each(function() {
      $(this).on("load", function() {

        $(this).css({
          height: "auto",
          width: "auto"
        }).addClass('loaded').parent().removeClass("loading");
      });

      $(this).not('.loaded').attr("src", $(this).attr("data-src"));
    });
  }
};

$.fn.lazy = function() {
  var matched = $(this);

  matched.each(function() {
    new Lazy($(this)); // eslint-disable-line no-new
  });
  return matched;
};
