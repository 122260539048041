import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import '../utils/forms';
import ModalFns from '../utils/modalfns';

export default function Captcha() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

Captcha.prototype = {
  attach: function(msg, element) {
    // var _this = this;
    $("#captcha-reload-button-anchor").off("click").on("click", function(e) {
      e.preventDefault();
      $("#captcha-image-anchor").empty().html('<img src="' + config.captchaImageUrl + '?' + Math.random() + '"/>');
    });
  }
};

// Extend Modal Functions
Captcha.prototype = $.extend(Captcha.prototype, ModalFns.prototype);
