import $ from 'jquery';
import Pubsub from 'pubsub';

export default function SecupayClient() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}
SecupayClient.prototype = {
  attach: function (msg, element) {

    const IFRAME_URL = 'https://cc.secupay.com';
    const iframeElement = document.getElementById('iframe');
    var iframeContentWindow;
    try {
      iframeContentWindow = iframeElement.contentWindow;
    } catch (error) {
      iframeContentWindow = null;
    }

    /**
     * Set iframe parameters
     */
    window.addEventListener('load', function (e) {
      if (iframeContentWindow != null) {
        // Set theme
        iframeContentWindow.postMessage(
          {
            action: "set-theme",
            theme: 0 // 0 = secupay, 1 = custom
          },
          IFRAME_URL
        );

        // Set language (optional)
        iframeContentWindow.postMessage(
          {
            action: "set-lang",
            lang: "de"
          },
          IFRAME_URL
        );
      }
    }, false);

    /**
     * Respond on iframe messages
     */
    window.addEventListener('message', function (e) {

      // Check origin
      if (e.origin !== IFRAME_URL) {
        return;
      }

      switch (e.data.action) {

        // Iframe height changed. This is raised on load, and after validation.
        case 'cc-iframe-height':{
          iframeElement.height = e.data.documentHeight + 'px';
          break;
        }
        // Iframe sends data. These may be valid or not.
        case 'prepared-cc-data':{
          let data = e.data.data;
          if (e.data.data.allFieldsAreValid) {
            $('#cardHolder').val(data.encryptedData.masked.card_owner);
            $('#cardNumber').val(data.encryptedData.masked.card_number);
            $('#expirationDate').val(data.expDate.value);
            $('#company').val(data.cardNumber.company);
            $('#cryptedContainer').val(data.encryptedData.crypted_container);
            $('#cryptedSkey').val(data.encryptedData.crypted_skey);
            $('#containerHash').val(data.encryptedData.container_hash);
            $('#keyFilename').val(data.encryptedData.key_filename);
            $('#paymentBaseForm').submit();
          }
          break;
        }
        default:
          break;
      }
    }, false);
  }
};

