// Everything that should happen on every page
import $ from "jquery";
import Pubsub from "pubsub";

export default function Navi() {
  Pubsub.subscribe('res', $.proxy(this.checkBreakpointChanges, this));
}

Navi.prototype = {
  navTriggerDelay: 250,
  mobileNavEnabled: false,
  mobileNavOpen: false,
  mobileNavLvl: 0,
  $nav: $('.nav-main'),
  navImageUrls: [],
  $lastHoveredMainNav: $(),

  // eslint-disable-next-line complexity
  checkBreakpointChanges: function (msg, on) {
    if (on) {
      switch (msg) {
        case 'res.lg':
          this.mobileNavEnabled = false;
          break;
        case 'res.md':
          this.mobileNavEnabled = false;
          break;
        case 'res.sm':
          this.mobileNavEnabled = true;
          break;
        case 'res.xs':
          this.mobileNavEnabled = true;
          break;
      }
    }
  },

  init: function () {
    let that = this,
    hoverTimer = null;
    var $body = $('body');

    // Desktop
    let $firstLvlElements = $('> li', this.$nav);

    that.createFlyoutWrappersAndImages();

    $firstLvlElements.on('mouseenter', function () {
      if (!that.mobileNavEnabled) {
        let $hoveredElement = $(this);

        if (hoverTimer) {
          clearTimeout(hoverTimer);
        }

        hoverTimer = setTimeout(function () {
          that.$nav.trigger('$elementSelected', [$hoveredElement, 'firstLevel']);
        }, that.navTriggerDelay);
      }
    });

    $firstLvlElements.on('mouseleave', function () {
      if (!that.mobileNavEnabled) {
        if (hoverTimer) {
          clearTimeout(hoverTimer);
        }

        if ($(this).hasClass('hover')) {
          that.toggleFlyout($(this));
        }
      }
    });

    $('li', $firstLvlElements).on('mouseenter', function () {
      if (!that.mobileNavEnabled) {
        let $hoveredElement = $(this);

        if ($hoveredElement.hasClass('has-children')) {
          // second level
          that.$nav.trigger('$elementSelected', [$hoveredElement, 'secondLevel']);
        } else {
          // third level
          that.$nav.trigger('$elementSelected', [$hoveredElement, 'thirdLevel']);
        }
      }
    });

    // eslint-disable-next-line complexity
    this.$nav.on('$elementSelected', function (e, $selectedElement, level) {
      let imgUrl = $selectedElement.data('navimg') || '';
      that.navImageUrls[level] = imgUrl;

      if (level === 'firstLevel') {
        that.navImageUrls = {};
        that.navImageUrls[level] = imgUrl;
        that.$currentNavImage = $('img', $selectedElement);

        that.toggleFlyout($selectedElement);
      } else if (level === 'secondLevel') {
        imgUrl = imgUrl || that.navImageUrls.firstLevel;
      } else {
        imgUrl = imgUrl || that.navImageUrls.secondLevel || that.navImageUrls.firstLevel;
      }

      that.setFlyoutImage(imgUrl);
    });


    // Mobile
    $('.mobile-menu-toggle-wrapper').on('click', function(e) {
      e.preventDefault();
      $('.js-toggle-mobile-menu').toggleClass('active');
      $body.toggleClass('show-mobile-menu');
    });

    $('.js-toggle-next').on('click', function(e) {
      if ($body.hasClass('show-mobile-menu') && !$(this).parent('li').hasClass('active')) {
        e.preventDefault();
        e.stopPropagation();
        var $this = $(this);
        that.closeNaviElementsOtherPath($this);

        $this.parent('li').toggleClass('active');
      }
    });
  },

  //only for mobile
  closeNaviElementsOtherPath: function($selectedElement) {
    var selectedPath = $selectedElement.attr('data-path');
    var $naviMain = $('#navi-main');
    $naviMain.find('li').each(function() {
      var path = $(this).find('a').attr('data-path');
      if(path && selectedPath && selectedPath.indexOf(path) === -1) {
        $(this).removeClass('active');
      }
    });
  },

  //only for desktop
  setFlyoutImage: function (src) {
    if (src) {
      this.$currentNavImage.attr('src',src);
      this.$currentNavImage.show();
    } else {
      this.$currentNavImage.hide();
    }
  },
  createFlyoutWrappersAndImages: function () {
    let imageWrapper = document.createElement('li'),
      image = document.createElement('img');

    imageWrapper.setAttribute('class', 'nav-img-wrapper');
    $(imageWrapper).append(image);
    $('ul.nav-off--lvl-1', this.$nav).append(imageWrapper);
  },
  toggleFlyout: function ($selectedElement) {
    if ($('ul', $selectedElement).length > 0) {
      $selectedElement.toggleClass('hover');
    }
  }
};
