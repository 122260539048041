import $ from 'jquery';
import Pubsub from 'pubsub';
import '../utils/sliderknobs';
import CartInput from '../components/cart_input';
import config from '../config/config';
import Loading from '../components/loading';
import TrackingHelper from '../utils/trackinghelper';
import GTMHelper from "../utils/gtmHelper";
import log from "consolelog";
import Html5Validation from '../utils/html5validation';
import ScrollBox from '../components/scroll-box';

import '../utils/tabstocollapse';
import '../utils/zoom';
import '../utils/slidercontrols';
import '../utils/sliderlazyload';
import '../utils/form_elements/plusminus';
import 'swipe';
import 'ratings';

export default function Ads() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));

  this.cart_input = new CartInput();
  this.gtmHelper = new GTMHelper();
  this.notepad_input = new CartInput(".notepad-header");
  this.loading = new Loading();
  this.html5validation = new Html5Validation();
  this.trackingHelper = new TrackingHelper();
  this.isChooseBuy = false;
  this.isCoupon = false;
  this.submitTarget = null; // reservation | delivery | null
  this.determineVariation(config.adsHashForwardPath, config.adsHashForwardItemId);
}

Ads.prototype = {
  attach: function (msg, element) {
    const that = this;
    const $buyOrReserveRadioElems = $('input[type=radio][name=buy-or-reserve]');

    that.html5validation.addValidationHandler();
    that.html5validation.validateOnSubmit();
    $buyOrReserveRadioElems.length > 0 ? this.initChooseBuy($buyOrReserveRadioElems) : $('.local-price').removeClass("hidden");
    that.initGallery();

    if (element === document || $(element).hasClass("prd-top")) {
      //Check if zoom images are big enough..
      window.s7jsonResponse = function (data, reqId) {
        if (parseInt(data['image.width']) <= 870 || parseInt(data['image.height']) <= 489) {
          $(".main-image img").eq(parseInt(reqId)).parent().addClass("too-small");
          if (parseInt(reqId) === 0) {
            that.thumbsCallback(0);
          }
        }
      };
    }

    // todo: old stuff, find a better solution
    new ScrollBox().init(200,
        '.service-tab-service-panel',
        '.service-tab-scroll-box-container',
        '.service-tab-service-headline');

    //open right tab/panel
    $(".links a[href='#more-infos']").off("click").on("click touchstart", function () {
      $(".panel-heading.panel-in").find("a[href='#detail-pane']:visible").on("click touchstart");
      $("a[href='#service']:visible").on("click touchstart");
    });
    $("a.more-infos").off("click").on("click touchstart", function () {
      $(".panel-heading").not(".panel-in").find("a[href='#detail-pane']:visible").on("click touchstart");
      $("a[href='#detail']:visible").on("click touchstart");
    });

    $("a.history-back-link").off("click").on("click touchstart", function (e) {
      e.preventDefault();
      history.back();
    });

    $("#backRefLink").off("click").on("click touchstart", function (e) {
      e.preventDefault();
      $("#backLink").on("click touchstart");
    });

    //put it on the notepad
    $(".notepad-link").off("click").on("click touchstart", function (e) {
      e.preventDefault();
      $.get($(this).attr("href"), function (data) {
        that.notepad_input.input(data.markup, data.count);
      }).fail(function (jqXhr, error) {
      });
    });

    $(".reminder-link").off("click").on("click touchstart", function (e) {
      e.preventDefault();
      $.get($(this).attr("href") + "&email=" + $("<div/>").text($("#reminder_email").val()).html(), function (data) {
        $("#reminder_callback").html(data.markup);
      }).fail(function (jqXhr, error) {
      });
    });

    let initPanelSliders = $.proxy(this.initPanelSliders, this);
    $(".page-detail-view .nav-tabs li a", element).off("click").on("click touchstart", function (e) {
      e.preventDefault();
      $(this).tab('show');
      initPanelSliders();
    });
    if (element === document) {
      $(".first-tabs .nav-tabs a:first, .second-tabs .nav-tabs a:first").tab('show');
    }

    $(".prd-slider-tabs .panel-collapse")
        .off("show.bs.collapse.sliders").on("show.bs.collapse.sliders", initPanelSliders);

    $(".order-count", element).plusminus();

    $(".add-to-cart").off("click").on("click touchstart", function (e) {
      e.preventDefault();
      if ((that.isChooseBuy && that.submitTarget) || that.isCoupon) {
        if (e.type === "touchstart") {
          that.submit(e);
        } else if (e.type === "click") {
          that.submit(e);
        }
      }
    });


    // get value from link-value
    $("[name='var[import:supply_coupon_value]']").val($(".coupon-value-list a.active").data('link-value'));
    $("[name='var[import:color]']").val($(".color-list .color-list-variation.active").data('link-value'));

    // Coupon Value Spots change
    $(".coupon-value-list a").off("click").on("click touchstart", function (e) {
      e.preventDefault();
      $("[name='var[import:supply_coupon_value]']").val($(this).data('link-value'));
      that.getVariationRequest(e);
    });
    that.initCoupons();
    // Select Boxes change
    $("[data-update]").off("change").on("change", function (e) {
      that.getVariationRequest(e);
    });
  },
  formatNumberToPriceString: function (number) {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})
        .format(number);
  },
  showCouponPrice: function (priceValue) {
    const that = this;
    let priceString = that.formatNumberToPriceString(Number(priceValue));
    $("#js-price").html("<span>" + priceString + "</span>");
  },
  checkAndSetB2bCouponPrice: function () {
    const that = this;
    let $quantityField = $(".js-b2bCoupon [name='quantity']");
    let quantity = $quantityField.val();
    let $couponValueField = $(".js-b2bCoupon [name='couponValue']");
    let couponValue = $couponValueField.val();
    if (!$quantityField.hasClass("has-error") && quantity !== ''
        && !$couponValueField.hasClass("has-error") && couponValue !== '') {
      let priceValue = Number(couponValue * quantity);
      that.showCouponPrice(priceValue);
      $("#js-deliveryCosts").show();
    }
  },
  hideVariableCouponPlaceholder: function () {
    let $input = $("[name='variableCouponValue']");
    $input.attr('placeholder', '');
    $input.addClass('active');
    $("#input-currency").show();
  },
  showVariableCouponPlaceholder: function () {
    let $input = $("[name='variableCouponValue']");
    const phText = $input.data('placeholder');
    $input.attr('placeholder', phText);
    $input.removeClass('active');
    $("#input-currency").hide();
  },
  determineVariation: function (forwardPath, initialItemId) {
    "use strict";
    let that = this;
    let hash = window.location.hash,
        itemIdString = "itemId=",
        itemIdStart = hash.substring(hash.indexOf(itemIdString) + itemIdString.length),
        indexOfAnd = itemIdStart.indexOf("&"),
        itemId = indexOfAnd > 0 ? itemIdStart.substring(0, indexOfAnd) : itemIdStart;
    if (itemId !== "undefined" && itemId !== "" && itemId !== initialItemId) {
      $.get(forwardPath + "&itemId=" + itemId + "&previousId=" + initialItemId, function (jsonData) {
        that.loading.stop();
        that.update(jsonData);
        that.toggleAvailabilityBlock();
      }).fail(function (jxXhr, e) {
      });
    }
  },
  update: function (jsonData) {
    let $htmlData = $(jsonData.markupTop);
    const $prdTop = $(".prd-top");
    $(".popover").remove();
    $(".swipe", $htmlData).css({
      visibility: "hidden"
    });

    $prdTop.replaceWith($htmlData);
    $(".markup_features_tab").replaceWith($(jsonData.markupFeatures));
    $(".markup-article-number").replaceWith($(jsonData.markupArticleNumber));
    this.gallery_swipe = false;

    $("#service-pane .panel-body").empty().html(jsonData.markupBottom);
    if (jsonData.markupBottom === "") {
      //Activate Detail Pane
      $(".panel-heading").not(".panel-in").find("a[href='#detail-pane']:visible").on("click touchstart");
      $("a[href='#detail']:visible").on("click touchstart");
    }

    $("#alternative").empty().html(jsonData.possibleAlternatives);
    if (jsonData.possibleAlternatives === "") {
      //Hide Alternatives
      $("#alternative, [href='#alternative']").css({
        visibility: "hidden"
      });
      //Activate Detail Pane
      $(".nav-tabs li").not(".active").find("a[href='#from-this']:visible").on("click touchstart");
      $("a[href='#from-this']:visible").on("click touchstart");
    } else {
      $("#alternative, [href='#alternative']").css({visibility: "visible"});
    }

    Pubsub.publish("core.attach", $prdTop);
  },
  initGallery: function () {
    const that = this;
    const makeGallery = function () {
      that.gallery_swipe = $(".main-swipe").Swipe({
        callback: this.thumbsCallback,
        continuous: false,
        startSlide: config.activeADSImage,
        transitionEnd: function (index, elem) {
          $(elem).addClass('active');
        }
      }).controls().knobs().data("Swipe");

      this.responsive(this.last_msg, true);
    };

    if (!this.gallery_swipe) {
      this.gallery_swipe = true; //async loading, do not call me again.
      makeGallery.call(that);
    }
  },

  getVariationRequest: function (e) {
    const that = this;
    that.loading.load();
    const $element = $(e.target);
    const category = $element.data('category');
    const action = $element.data('action');
    const value = $element.data('value');
    that.trackingHelper.event(category !== undefined ? category : '', action, value !== undefined ? value : '');
    $.get(config.variationChangePath + "?q=pda_top&" + $("#article-form").serialize(), function (jsonData) {
      that.loading.stop();
      that.update(jsonData);
      location.replace("#itemId=" + $("input[name=itemId]").val());
    }).fail(function (jqXhr, e) {
    });
  },

  initCoupons: function () {
    let that = this;
    this.isCoupon = !!$("div.ads-coupon").length;
    // Color Spots change
    $(".color-list .valid .color-list-variation").unbind().on("click touchstart", function (e) {
      e.preventDefault();
      $("[name='var[import:color]']").val($(this).data('link-value'));
      that.getVariationRequest(e);
    });
    // Variabel Coupon Value Spots change
    const $variableCouponValue = $("[name='variableCouponValue']");
    $(".coupon-value").unbind().on("click touchstart", function (e) {
      $(".coupon-value").removeClass("active");
      $(this).addClass("active");
      let $couponInput = $("[name='variableCouponValue']");
      $couponInput.val('');
      $couponInput.removeClass('has-error');
      $couponInput.closest('.validation-group').find('.form-error').hide();
      that.showVariableCouponPlaceholder();
      let priceValue = $(this).data('value');
      that.showCouponPrice(priceValue);
      $("[name='couponValue']").val(priceValue);
    });
   $variableCouponValue.blur(function () {
      let $inputField = $(this);
      let priceValue = $inputField.val();
      if (!$inputField.hasClass("has-error") && priceValue !== '') {
        that.showCouponPrice(priceValue);
        $("[name='couponValue']").val(priceValue);
        $(".coupon-value").removeClass("active");
      }
      if (priceValue === '' && !$inputField.hasClass("has-error")) {
        that.showVariableCouponPlaceholder();
      }
    });
    $variableCouponValue.focus(function () {
      that.hideVariableCouponPlaceholder();
    });
    $(".js-b2bCoupon [name='couponValue'], .js-b2bCoupon [name='quantity']").blur(function () {
      that.checkAndSetB2bCouponPrice();
    });
  },

  toggleAvailabilityBlock: function () {
    const $availabilityBlock = $('.availability-block');
    $availabilityBlock.addClass('hide');
    $availabilityBlock.addClass(this.submitTarget);
    $availabilityBlock.filter("[data-choose='" + this.submitTarget + "']").removeClass('hide');
  },

  initChooseBuy: function ($buyOrReserveRadioElems) {
    let that = this;
    this.isChooseBuy = true;
    let $enabledBuyOrReserveRadioElem;
    const $chooseBuyRelatedElems = $('.choose-buy-related-item');
    const $chooseSubmitTypeRelatedElems = $('.choose-submit-type-related-item');
    const $chooseBuyContent = $('.choose-buy__content');
    const $availabilityBlock = $('.availability-block');
    const $buyButton = $("#buyButton");

    // select first enabled (not disabled) radio and related element if not already been one selected
    // cause attach is also being triggered on breakPointChange
    const $alreadySelectedBuyOrReserveRadioElem = $buyOrReserveRadioElems.filter(':checked');
    if ($alreadySelectedBuyOrReserveRadioElem.length > 0) {
      $enabledBuyOrReserveRadioElem = $alreadySelectedBuyOrReserveRadioElem;
    } else {
      $enabledBuyOrReserveRadioElem = $buyOrReserveRadioElems.filter(':enabled').first();
      // handle if everything is disabled
      if ($enabledBuyOrReserveRadioElem.length > 0) {
        $enabledBuyOrReserveRadioElem.prop('checked', true);
      }
    }

    $chooseBuyRelatedElems.removeClass('visible');
    $availabilityBlock.addClass('hide');
    let priceElement = that.submitTarget === "delivery" ? $('.global-price') : $('.local-price');
    priceElement.removeClass("hidden");
    that.toggleGlobalLocalPrice(that.submitTarget);
    if ($enabledBuyOrReserveRadioElem.length > 0) {
      let selectedRadio = $enabledBuyOrReserveRadioElem.val();
      that.updateBuyButton(selectedRadio, $buyButton);
      $chooseBuyRelatedElems.filter("[data-choose='" + selectedRadio + "']").addClass('visible');
      this.setSubmitTarget($enabledBuyOrReserveRadioElem.val());
      $chooseSubmitTypeRelatedElems.filter("[data-choose='" + this.submitTarget + "']").addClass('visible');
      $chooseBuyContent.filter("[data-choose='" + that.submitTarget + "']").addClass('visible');
      that.toggleAvailabilityBlock();

      // add eventListener to on radio change to set related element and value based submitUrl
      $buyOrReserveRadioElems.off("change").on("change", function () {
        let selectedOption = this.value;
        that.updateBuyButton(selectedOption, $buyButton);
        $chooseBuyRelatedElems.removeClass('visible');
        $chooseBuyRelatedElems.filter("[data-choose='" + selectedOption + "']").addClass('visible');
        that.setSubmitTarget(this.value);
        $chooseSubmitTypeRelatedElems.removeClass('visible');
        $chooseSubmitTypeRelatedElems.filter("[data-choose='" + that.submitTarget + "']").addClass('visible');
        $chooseBuyContent.removeClass('visible');
        $chooseBuyContent.filter("[data-choose='" + that.submitTarget + "']").addClass('visible');
        that.toggleGlobalLocalPrice(that.submitTarget);
        that.toggleAvailabilityBlock();
      });
    } else {
      $buyButton.removeClass('visible');
    }
  },

  toggleGlobalLocalPrice: function (state) {
    const $localPriceVal = $('.local-price');
    const $globalPriceVal = $('.global-price');
    if (state === "delivery") {
      $localPriceVal.addClass("hidden");
      $globalPriceVal.removeClass("hidden");
    } else {
      $localPriceVal.removeClass("hidden");
      $globalPriceVal.addClass("hidden");
    }
  },

  updateBuyButton: function (selectedOption, $buyButton) {
    $buyButton.attr("data-choose", selectedOption);
    selectedOption === "delivery" || selectedOption === "reservationOneStock"
        ? $buyButton.addClass("visible") : $buyButton.removeClass("visible");
  },

  setSubmitTarget: function (val) {
    this.submitTarget = val.toLowerCase().indexOf('delivery') > -1 ?
        'delivery' :
        val.toLowerCase().indexOf('reservation') > -1 ?
            'reservation' :
            null;
  },

  submit: function (e) {
    const that = this;
    let $form = $("#article-form"),
        postUrl = $form.attr("action"),
        noError = true;
    // validation for b2b
    if ($form.hasClass("js-b2bCoupon")) {
      $form.find("input.form-control").each(function () {
        let fieldHasNoError = that.html5validation.validateField(this);
        noError = noError && fieldHasNoError;
      });
    }
    if (noError) {
      if (this.isChooseBuy) {
        postUrl = this.submitTarget === 'delivery' ? $form.data('delivery-basket-url') : $form.data('reservation-basket-url');
      }
      $.post(postUrl, $form.serialize(), function (data) {
        that.cart_input.input(data.markup, data.count, data.total, null, data.title, data.addedToBasket, data.basketUrl);
        if (data.addedToBasket) {
          that.gtmHelper.pushEvent(data.dataLayer);
        }
      }).fail(function (jqXhr, e) {
        log(e);
      });
    }
  },
  // eslint-disable-next-line complexity
  responsive: function (msg, on_off) {
    const that = this;
    if (on_off) {
      this.last_msg = msg;
      this.removeSwipes();
    }
    switch (msg) {
      case 'res.xs':

        if (on_off) { // entered state xs
          $(".prd-tabs, .prd-slider-tabs").ttcol({
            collapse: true
          });

          $(".last-seen-swipe").Swipe().knobs().lazy();
        } else { // left state xs

        }
        break;
      case 'res.sm':
        if (on_off) { // entered state sm
          $(".last-seen-swipe").Swipe({
            pair: 4
          }).knobs().lazy();

          $(".prd-tabs, .prd-slider-tabs").ttcol({
            collapse: false
          });

          $(".thumb-swipe").Swipe({
            direction: 'vertical',
            pair: 4,
            continuous: false
          }).controls();
          this.addThumbsClick();
        } else { // left state sm

        }
        break;
      default:
        if (on_off) {
          $(".thumb-swipe").Swipe({
            direction: 'vertical',
            pair: 4,
            continuous: false,
            transitionEnd: function (index, elem) {
              //Tracking
              let $element = $(elem).parents('.swipe').next();
              let category = $element.data('category');
              let action = $element.data('action');
              let value = $element.data('value');
              that.trackingHelper.event(category !== undefined ? category : '', action, value !== undefined ? value : '');
            }
          }).controls();

          this.addThumbsClick();
          $(".last-seen-swipe").Swipe({
            pair: 6,
            continuous: false
          }).controls().lazy();
        }
    }
    if (on_off) {
      this.initPanelSliders();
      Pubsub.publish("core.attach", $(".last-seen-swipe"));
    }

    this.zoom = $(".main-image div.pda-big-img-view").zoom().data('Zoom');
  },
  addThumbsClick: function () {
    const that = this;
    $(".thumbs .gallery-thumb").off("click").on("click touchstart", function () {
      that.gallery_swipe.slide($(".thumbs .gallery-thumb").index($(this)));
    });
  },
  thumbsCallback: function (pos) {
    setTimeout(function () {
      let thumb_swipe = $(".thumb-swipe").data("Swipe");
      if (thumb_swipe !== undefined) {
        thumb_swipe.slide(Math.floor(pos / 4));
      }
    }, 0);

    const $mainSwipeImages = $('.pda-big-img-view');
    if ($mainSwipeImages.filter('.active').length === 1 || pos !== 0) {
      $mainSwipeImages.removeClass('active');
    }

    $(".thumbs .gallery-thumb").removeClass("active").eq(pos).addClass("active");
  },
  initTabSwipes: function (res) {
    const swipes = $(".prd-slider-tabs .swipe");
    swipes.each(function () {
      if ($(this).data("Swipe")) {
        $(this).data("Swipe").kill();
      }
    });
    if (res === "res.xs") {
      swipes.Swipe({pair: 2}).knobs().lazy();
    } else if (res === "res.sm") {
      swipes.Swipe({pair: 3}).knobs().lazy();
    } else {
      swipes.Swipe({pair: 4, continuous: false}).controls().lazy();
    }
  },
  initPanelSliders: function (e) {
    const that = this;
    setTimeout(function () {
          that.initTabSwipes(that.last_msg);
          Pubsub.publish("core.attach", $(".prd-slider-tabs .swipe"));
        },
        0);
  },
  removeSwipes: function () {
    if (this.zoom) {
      this.zoom.kill();
    }

    $(".thumbs .gallery-thumb").off("click");

    //eslint-disable-next-line consistent-return
    $(".swipe").each(function () {
      if ($(this).hasClass("main-swipe")) {
        return true;
      }

      if ($(this).data("Swipe")) {
        $(this).data("Swipe").kill();
      }
    });
  },
  pinchStart: function () {
    $(".swipe").css("-ms-touch-action", "none");
  },
  pinchEnd: function () {
    $(".swipe").css("-ms-touch-action", "pan-y");
  }
};
