import $ from "jquery";

export default class Loading {

  // for more see https://github.com/tobiasahlin/SpinKit
  // and update loader.scss
  constructor(options = {}) {
    var defaults = {
      el: '.js_main-content-wrapper',
      $loader: $(),
      markup:
        `<div class="loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
        </div>`
    };
    this.options = Object.assign(defaults, options);
  }

  load() {
    this.options.$loader = $(this.options.el + " > .loader");

    if (this.options.$loader.length === 0) {
      $(this.options.el).prepend(this.options.markup);
      this.options.$loader = $(this.options.el + " > .loader");
    }

    this.options.$loader.fadeIn(300);
  }

  stop() {
    this.options.$loader.fadeOut(300, function() {
      $(this).remove();
    });
  }
}
