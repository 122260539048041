import $ from 'jquery';
import Html5Validation from '../utils/html5validation';

export default function CardLayout() {
  this.init();
}

CardLayout.prototype = {
  init: function () {
    $(".cardlayout-input").on("focus blur", function (e) {
      $(this).parents(".validation-group").toggleClass("focused-or-filled", "focus" === e.type || this.value.length > 0);
    }).trigger("blur");

      let html5validation = new Html5Validation();
      html5validation.addValidationHandler();
      html5validation.validateOnSubmit();
  }
};
