/* eslint-disable no-new */
// XXX: New actions trigger several side effect. Consider refactoring this behavior
import $ from "jquery";
import Pubsub from "pubsub";
import enquire from "enquire";

import Ads from "./views/ads";
import Product from "./views/product";
import Account from "./views/account";
import Checkout from "./views/checkout";
import Cart from "./views/cart";
import General from "./views/general";
import Navi from "./views/navi";
import Content from "./views/content";
import ReferToAFriend from "./views/referToAFriend";
import Captcha from "./views/captcha";
import Raffle from "./views/raffle";
import Trackinghelper from "./utils/trackinghelper";
import StickyFooter from "./utils/stickyFooter";
import Service from "./components/teaser/service";
import ProductBox from "./ece/product-box";
import SliderInitializer from "./utils/slider-initializer";
import "./utils/formValidator";
import "./views/shopform";
import "./ece/sliderTeaser";
import MenuJson from "./ece/menujson";
import Ece from "./ece/ece";
import CardLayout from "./ece/cardlayout";
import ShopTheLook from "./ece/shopTheLook";

export default function App() {
}

// eslint-disable-next-line complexity,max-statements
App.prototype.initialize = function() {
  //js for the pages
  if ($(".page-detail-view").length !== 0) {
    new Ads();
    new ReferToAFriend();
  } else if ($(".account").length !== 0) {
    new Account();
  } else if ($(".basket-layout").length !== 0) {
    new Cart();
  } else if ($(".checkout").length !== 0) {
    new Checkout();
  } else if ($(".page-prd-list").length !== 0) {
    new Product();
  } else if ($(".no-search-result").length !== 0) {
    new Product();
  } else if ($(".cardlayout-container").length !== 0) {
    new CardLayout();
  } else if ($(".content").length !== 0) {
    new Content();
  }

  // accordion behaviour for service teaser
  if ($(".accordion-teaser").length !== 0) {
    new Service();
  }
  if($(".prd-box").length !== 0) {
    new ProductBox();
  }
  if ($(".multi-add-items").length !== 0) {
    new ShopTheLook();
  }
  new General();
  new Navi().init();
  new StickyFooter().init();
  new SliderInitializer().multiInit();
  new Trackinghelper();
  new Raffle();
  new Captcha();
  new MenuJson().init();
  new Ece().init();

  //Do not edit below this line ;)
  //Subscribers can attach their events to the document
  Pubsub.publish("core.attach", document);

  //Register resolutions
  enquire.register("screen and (max-width:767px)", {
    match: function() {
      Pubsub.publish("res.xs", true);
    },
    unmatch: function() {
      Pubsub.publish("res.xs", false);
    }
  });
  enquire.register("screen and (min-width:768px) and (max-width:1024px)", {
    match: function() {
      Pubsub.publish("res.sm", true);
    },
    unmatch: function() {
      Pubsub.publish("res.sm", false);
    }
  });
  enquire.register("screen and (min-width:1025px) and (max-width:1199px)", {
    match: function() {
      Pubsub.publish("res.md", true);
    },
    unmatch: function() {
      Pubsub.publish("res.md", false);
    }
  });
  enquire.register("screen and (min-width:1200px)", {
    match: function() {
      Pubsub.publish("res.lg", true);
    },
    unmatch: function() {
      Pubsub.publish("res.lg", false);
    }
  });

  //example: login let opportunities toggle so only one field is shown at the time
  let toggleContentElements = $(".js_toggle-content");

  if (toggleContentElements.length > 0) {
    toggleContentElements.each(function (i, toggleContentElement) {
      $(toggleContentElement).on("click.toggleHandler", ".folded .js_toggle-trigger", toggleHandler);
    });
  }

  function toggleHandler(e) {
    e.preventDefault();
    let $toggleContentWrapper = $(e.delegateTarget);
    let $toggleItems = $(".js_toggle-item", $toggleContentWrapper);

    $($toggleContentWrapper).off("click.toggleHandler");

    $toggleItems.each(function(i, toggleItem) {
      $(toggleItem).slideToggle(200, function () {
        if (i === $toggleItems.length - 1) {
          $toggleContentWrapper.children().toggleClass("folded");
          $toggleContentWrapper.on("click.toggleHandler", ".folded .js_toggle-trigger", toggleHandler);
        }
      });
    });
  }
};
