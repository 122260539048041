import $ from "jquery";

export default function Ece() {
}

Ece.prototype = {

  init: function () {
    // burger menu
    $(".hdr-header__burger").click(function () {
      $("body").toggleClass("menu-active");
    });

    // Search button
    $(".hdr-header__icon-search, .hdr-search__close, .mnu-infolist__icon-search").click(function (e) {
      e.preventDefault();
      $("body").toggleClass("search-active");
    });
  }
};
