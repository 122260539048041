// noinspection JSAnnotator
import $ from 'jquery';
// noinspection JSAnnotator
import 'jqueryui';
// XXX: Has the side effect of registering a jquery plugin
import 'mask';
import Pubsub from 'pubsub';
import Html5Validation from '../utils/html5validation';
import Loading from '../components/loading';
import Coupon from '../components/coupon';
import GTMHelper from "../utils/gtmHelper";
import SecupayClient from "../utils/secupayClient";
import SepaDirectDebit from "../utils/sepaDirectDebit";

export default function Checkout() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.loading = new Loading();
  this.coupon = new Coupon();
  this.gtmHelper = new GTMHelper();
  this.secupay = new SecupayClient();
}

Checkout.prototype = {
  // eslint-disable-next-line max-statements
  attach: function (msg, element) {
    var that = this;
    var html5validation = new Html5Validation();

    $(".voucher-toggle").off("click").click(function () {
      $(this).toggleClass("toggled");
      $(this).parent().parent().find(".voucher-input").toggleClass("hidden");
    });

    $("#orderSubmitBtn", element).off("click").on("click", function () {
      $(this).attr("disabled", "disabled");
    });

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();
    $("[data-date]", element).mask("99.99.9999");
    $(".tip").popover({
      trigger: "hover"
    });

    if ($(".checkout-address").length !== 0) {
      // Addresses
      $("#packstation-checkbox").off("click").click(function () {
        let isChecked = $(this).is(':checked');
        $("#address-form-packstation").toggle(isChecked);
        $("#address-form-default").toggle(!isChecked);
      });
    }
    const showShippingAddressSelectorPattern = "input[id=showShippingAddress]";
    const checkedDeliveryTypeSelectorPattern = "input[name=deliveryType]:checked";
    const checkedPaymentTypeSelectorPattern = "input[name=paymentType]:checked";
    const paymentAccordionHeading = $('.accordion > .accordion-heading');
    const personalDataBaseForm = $("#personalDataBaseForm");
    const deliveryBaseForm = $("#deliveryBaseForm");
    const paymentBaseForm = $("#paymentBaseForm");
    const showShippingAddressCheckBox = $("#showShippingAddress");

    // Datalayer tracking
    $(document).ready(function () {
      if (personalDataBaseForm.length) {
        that.trackFormInit(personalDataBaseForm, showShippingAddressSelectorPattern, 2);
      } else if (deliveryBaseForm.length) {
        that.trackFormInit(deliveryBaseForm, checkedDeliveryTypeSelectorPattern, 3);
      } else if (paymentBaseForm.length) {
        that.trackFormInit(paymentBaseForm, checkedPaymentTypeSelectorPattern, 4);
      }
    });
    $("#personalDataSubmitBtn").click(function (event) {
      event.preventDefault();
      that.trackFormSubmit(personalDataBaseForm, showShippingAddressSelectorPattern, 2);
    });
    $("#deliverySubmitBtn").click(function (event) {
      event.preventDefault();
      that.trackFormSubmit(deliveryBaseForm, checkedDeliveryTypeSelectorPattern, 3);
    });
    $("#paymentSubmitBtn").click(function (event) {
      event.preventDefault();
      const paymentType = document.querySelector('input[name="paymentType"]:checked').value;
      const paymentProvider = $(paymentBaseForm).attr('data-provider');
      if(paymentProvider === 'SECUPAY' && paymentType === 'CREDITCARD'){
        document.getElementById('iframe').contentWindow.postMessage({ action: "get-cc-data" }, "https://cc.secupay.com");
      } else if (paymentProvider === 'SECUPAY' && paymentType === 'SEPA') {
        if(SepaDirectDebit.validateForm()){
          that.trackFormSubmit(paymentBaseForm, checkedPaymentTypeSelectorPattern, 4);
        }
      } else{
        that.trackFormSubmit(paymentBaseForm, checkedPaymentTypeSelectorPattern, 4);
      }
    });
    // PEP
    const togglePepDataForm = ()=>{
      const required = $("#pepTrue").is(":checked");
      // eslint-disable-next-line max-len
      $.each(['pepFirstname', 'pepLastname', 'pepBirthDate', 'pepBirthLocation', 'pepStreet', 'pepStreetNumber', 'pepZipCode', 'pepCity'], (i, e)=>{
        let elementSelector =  $('#' + e);
        required ? elementSelector.attr('required', true) : elementSelector.removeAttr('required');
      });
      $('#pepPersonalDataForm')[required ? 'fadeIn' : 'fadeOut']('slow');
    };

    $(document).ready(togglePepDataForm);
    $('.pep-check').on('change', togglePepDataForm);

    if ($(".checkout-shipping").length === 0) {
      // Shipping
      const elements = ["shippingSalutation", "shippingFirstname", "shippingLastname", "shippingStreet",
        "shippingStreetNumber", "shippingZipCode", "shippingCity"];
      const shippingAddressFormId = "#shippingAddressForm";
      // eslint-disable-next-line complexity
      let toggleShippingAddressForm = function (duration) {
        let required = showShippingAddressCheckBox.is(":checked") && !showShippingAddressCheckBox.prop("disabled");
        for (let i in elements) {
          if ({}.hasOwnProperty.call(elements, i)) {
            let field = document.getElementById(elements[i]);
            if (field !== null) {
              required ? field.setAttribute("required", "required") : field.removeAttribute("required");
            }
          }
        }
        required ? $(shippingAddressFormId).show(duration) : $(shippingAddressFormId).hide(duration);
      };

      $(document).ready(toggleShippingAddressForm);
      showShippingAddressCheckBox.change(function () {
        toggleShippingAddressForm("slow");
      });

    } else {
      //Delivery
      $("form .panel-collapse", element).collapse({
        parent: "#accordion",
        toggle: false
      });
      $("form .panel.panel-default", element).off("click").on("click", function () {
        $(this).find(".panel-collapse").collapse("toggle");
        that.checkOptions($(this));
      });
      $(".address-block input[type=radio], .delivery-panel input", element).off("click").on("click", function () {
        that.checkOptions($(this));
      });
      that.checkOptions();

      //Payments
      //Custom Accordion for Payments with usable bodies
      $('.accordion > .accordion-body').hide();
      $('.accordion > .accordion-heading.open').next().show();
      paymentAccordionHeading.click(function () {
        if ($(this).attr('class').indexOf('open') === -1) {
          $(this).toggleClass("open").next().slideToggle();
        }
        $(".accordion-heading").not($(this)).removeClass("open");
        $(".accordion-body").not($(this).next()).slideUp();
        $(this).attr("disabled", "disabled");
      });

      let toggleDeliveryAtWorkForm = function () {
        const formId = '#id-delivery-at-work';
        const radioBtnAtWork = document.getElementById('radio-btn-atWork');
        radioBtnAtWork !== null && radioBtnAtWork.checked ? $(formId).show() : $(formId).hide();
      };
      let radioShippingSelector = $("input[class=radio-btn-shipping-type]:radio");
      radioShippingSelector.change(toggleDeliveryAtWorkForm);
      $(document).ready(toggleDeliveryAtWorkForm);

      let toggleSameDayDeliveryForm = function () {
        const formId = '#id-same-day-delivery';
        const radioBtnSdd = document.getElementById('radio-btn-sdd');
        radioBtnSdd !== null && radioBtnSdd.checked ? $(formId).show() : $(formId).hide();
      };
      radioShippingSelector.change(toggleSameDayDeliveryForm);
      $(document).ready(toggleSameDayDeliveryForm);
    }

    // Igenico 3DSecure Device properties
    $(document).ready(function () {
      let  jsEnabledSelector = $("#clientDeviceInformation\\.javaScriptEnabled");
      if (jsEnabledSelector.length > 0) {
        jsEnabledSelector.val(true);
        // Deprecated Web standard but required for Ingenico 3-D Secure version 2 for the authenticationFlow "browser"
        $("#clientDeviceInformation\\.javaEnabled").val(navigator.javaEnabled());
        $("#clientDeviceInformation\\.screenColorDepth").val(screen.colorDepth);
        $("#clientDeviceInformation\\.screenHeight").val(screen.height);
        $("#clientDeviceInformation\\.screenWidth").val(screen.width);
        $("#clientDeviceInformation\\.navigatorLocale").val(navigator.language);
        $("#clientDeviceInformation\\.timezoneOffsetUtcMinutes").val((new Date()).getTimezoneOffset());
      }
    });
  },
  checkOptions: function (element) {
    let addressValue = $("[name=addressType]:checked").val();
    let paymentValue = $("[name=paymentType]:checked").val();
    let deliveryValue = $("[name=deliveryType]:checked").val();

    if (addressValue !== "INVOICE") {
      addressValue = "DELIVERY";
    }

    if (this.options !== undefined) {
      let delivery_types = this.options.addressType[addressValue].deliveryType;
      delivery_types = this.arrayIntersect(delivery_types, this.options.paymentType[paymentValue].deliveryType);
      this.disableInputs(".delivery-panel .delivery-options input[type=radio]", delivery_types);

      let payment_types = this.options.addressType[addressValue].paymentType;
      payment_types = this.arrayIntersect(payment_types, this.options.deliveryType[deliveryValue].paymentType);
      this.disableInputs("form .panel-heading input[type=radio]", payment_types);

      let address_types = this.options.paymentType[paymentValue].addressType;
      address_types = this.arrayIntersect(address_types, this.options.deliveryType[deliveryValue].addressType);
      this.disableInputs(".address-block input[type=radio]", address_types, true);
    }
  },
  disableInputs: function (selector, valid_array, isAddress) {
    let that = this;
    $(selector).each(function () {
      if ($.inArray($(this).val(), valid_array) > -1) {
        that.setInputDisabled($(this), false);
      } else {
        that.setInputDisabled($(this), true);

        //Special Case for address
        if (isAddress && $.inArray("DELIVERY", valid_array) > -1) {
          if ($(this).val() !== "INVOICE") {
            that.setInputDisabled($(this), false);
          }
        }
        //Invalid combination handling
        if ($(this).prop("checked") && $(this).prop("disabled")) {
          $("[name=" + $(this).attr("name") + "]").not(":disabled").first().prop("checked", true).click().trigger("change");
          let invalidCombinationSelector = $(".invalid-combination");
          invalidCombinationSelector.show();
          $("body, html").animate({
            scrollTop: invalidCombinationSelector.offset().top
          }, 200);
        }
      }
    });
  },
  setInputDisabled: function ($input, disabled) {
    if (!disabled) {
      $input.attr("disabled", null);
      $input.parent().parent().removeClass("disabled");
    } else {
      $input.attr("disabled", "disabled");
      $input.parent().parent().addClass("disabled");
    }
  },
  arrayIntersect: function (a1, a2) {
    return $.map(a1, function (a) {
      return $.inArray(a, a2) < 0 ? null : a;
    });
  },
  trackFormInit: function (form, inputSelector, step) {
    if (form.length) {
      let selectedInput = $(inputSelector, form);
      if (selectedInput.length) {
        let isCheckBox = selectedInput.is(":checkbox");
        let option = isCheckBox ? selectedInput.is(":checked") && !selectedInput.prop('disabled') : selectedInput.val();
        this.gtmHelper.trackCheckoutTrackingContainer(step, option, null);
      }
    }
  },
  trackFormSubmit: function (form, inputSelector, step) {
    if (form.length) {
      let selectedInput = $(inputSelector, form);
      let isOption = false;
      let option = false;
      if (selectedInput.length) {
        let isCheckBox = selectedInput.is(":checkbox");
        if (isCheckBox) {
          isOption = selectedInput.is(":checked") && !selectedInput.prop('disabled');
          option = isOption;
        } else {
          option = selectedInput.val();
          let inputName = selectedInput.attr('name');
          let inputNameSelector = "input[name=" + inputName + "]";
          let firstInputValue = $(inputNameSelector, form).first().val();
          isOption = firstInputValue !== option;
        }
        if (isOption) {
          this.gtmHelper.trackCheckoutOptionTrackingContainer(step, option, form.submit());
        } else {
          form.submit();
        }
      }
    }
  }
};
