import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../loading';
import '../../config/config';

export default function Service() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.loading = new Loading();
}

Service.prototype = {
  attach: function(msg, element) {
    if (element === document) {
      var that = this;
      that.openServiceHash();
      $(window).on('hashchange', function() {
        that.openServiceHash();
      });
    }
  },

  openServiceHash: function() {
    if (document.location.hash) {
      var hash = document.location.hash;
      if ($(hash).length > 0) {
        var $nextPanel = $(hash).next('.panel-default');
        var $firstPanel = $(hash).parent().find('.panel-group .panel-heading:first').not('.panel-in');
        if ($nextPanel.length > 0) {
          $nextPanel.find('.panel-heading').not('.panel-in').click();
        } else if ($firstPanel.length > 0) {
          $firstPanel.click();
        }
      }
    }
  },

  scrollToElement: function($element, duration) {
    $('html, body').animate({
      scrollTop: $element.offset().top
    }, duration);
  }
};
