/* eslint-disable no-undef */

/* XXX: Several undefined references are used here.
 * mostly because of how the etracker library works.
 * Candidate for cleanup. *
 */

import $ from 'jquery';
import Pubsub from 'pubsub';

export default function Trackinghelper() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

Trackinghelper.prototype = {

  getTrackingCode: function() {
    if (typeof et_tracking_id !== 'undefined') {
      return et_tracking_id;
    }
    return null;
  },

  attach: function(msg, element) {
    var that = this;
    $('.eClick, .ewProductlist, .ePageTarget, .eLandingPage', element).off("mousedown");
    $('.eChange', element).off("change");
    $('.eClick', element).on("mousedown", function() {
      var $element = $(this);
      var category = $element.data('category');
      var action = $element.data('action');
      var value = $element.data('value');
      that.event(category !== undefined ? category : '', action !== undefined ? action : '', value !== undefined ? value : '');
    });


    $('.ewProductlist', element).on("mousedown", function() {
      var $element = $(this);
      var page = $element.data('page');
      that.wrapperProductList(page);
    });

    $('.ePageTarget', element).on("mousedown", function() {
      var $element = $(this);
      var pagename = $element.data('pagename');
      var target = $element.data('target');
      that.wrapPageTarget(pagename, target);
    });

    $('.eLandingPage', element).on("mousedown", function() {
      var $element = $(this);
      var lpage = $element.data('lpage');
      that.wrapLandingPage(lpage);
    });

    $('.eChange', element).change(function() {
      var $element = $(this);
      var category = $element.data('category');
      var action = $element.data('action');
      var value = $element.data('value');
      that.event(category !== undefined ? category : '', action !== undefined ? action : '', value !== undefined ? value : '');
    });

  },

  // eslint-disable-next-line max-params
  nm_et_eC_Wrapper_catch: function(a, b, c, g, l, h, p, n, m, d, q, w, k, e, u, f) {
    try {
      et_eC_Wrapper(a, b, c, g, l, h, p, n, m, d, q, w, k, e, u, f);
    } catch (e) {/* XXX: Consider logging the error somewhere other than the console */}
  },

  clickEvent: function(category, value) {
    if (value != null && value !== "") {
      try {
        ET_Event.eventStart(escape(category), escape(value), "Klick");
      } catch (e) {/* XXX: Consider logging the error somewhere other than the console */}
    }
  },
  event: function(category, action, value) {
    if (value != null && value !== "") {
      try {
        category = category !== '' ? escape(category) : et_pagename;
        ET_Event.eventStart(category, escape(value), escape(action));
      } catch (e) {/* XXX: Consider logging the error somewhere other than the console */}
    }
  },

  wrapPageTarget: function(pagename, target) {
    if (this.getTrackingCode() != null && typeof et_eC_Wrapper == 'function') {
      this.nm_et_eC_Wrapper_catch(this.getTrackingCode(), pagename, "", "", "", target, "", "", "", "", "", "", "", "", "");
    }
  },

  wrapLandingPage: function(landingPage) {
    if (this.getTrackingCode() != null && typeof et_eC_Wrapper == 'function') {
      // eslint-disable-next-line max-len
      this.nm_et_eC_Wrapper_catch(this.getTrackingCode(), et_pagename_wrapper_noescape, "", "", "", "", "", "", "", "", "", landingPage, "", "", "");
    }
  },

  wrapPage: function(pagename) {
    if (this.getTrackingCode() != null && typeof et_eC_Wrapper == 'function') {
      this.nm_et_eC_Wrapper_catch(this.getTrackingCode(), pagename, "", "", "", "", "", "", "", "", "", "", "", "", "");
    }
  },

  wrapperProductList: function(page) {
    if (this.getTrackingCode() != null && typeof et_eC_Wrapper == 'function') {
      var pagename = et_pagename_wrapper_noescape.replace(/Seite \d von/g, "Seite " + page + " von").replace(/ /g, "+");
      // eslint-disable-next-line max-len
      this.nm_et_eC_Wrapper_catch(this.getTrackingCode(), pagename, et_areas_wrapper_noescape, et_ilevel_noescape, et_url_wrapper_noescape, et_target_wrapper_noescape, et_tval_noescape, et_tonr_noescape, et_tsale_noescape, et_cust_noescape, et_basket_noescape, et_lpage_noescape, et_tag_noescape);
    }
  },

  wrapper: function(overlayName) {
    if (this.getTrackingCode() != null && typeof et_eC_Wrapper == 'function') {
      overlayName = overlayName ? overlayName : '';
      var pagename = overlayName + '/' + et_pagename_wrapper_noescape;
      // eslint-disable-next-line max-len
      this.nm_et_eC_Wrapper_catch(this.getTrackingCode(), pagename, et_areas_wrapper_noescape, et_ilevel_noescape, et_url_wrapper_noescape, et_target_wrapper_noescape, et_tval_noescape, et_tonr_noescape, et_tsale_noescape, et_cust_noescape, et_basket_noescape, et_lpage_noescape, et_tag_noescape);
    }
  }
};
