/**
 * Responsive Zoom Plugin
 */
import $ from 'jquery';
import 'jquerymobile';
import '../../libs/jquery.panzoom';

var defaults = {
};

function Zoom(el, options) {
  this.$el = el;
  this.options = options;
  this.container = null;
  this.init();
}

Zoom.prototype = {
  init: function() {
    if (!this.isMobile()) {
      $("img", this.$el).off("mouseover").on("mouseover", $.proxy(this.hover, this));
    }
    this.$el.off("click").on("click", $.proxy(this.openFullscreen, this));
  },
  kill: function() {
    this.out({
      pageX: -1,
      pageY: -1
    });
    $("img", this.$el).off("mouseover");
  },
  move: function(e) {
    var localPoint = [e.pageX - this.current_img.offset().left, e.pageY - this.current_img.offset().top];
    var bigPoint = [localPoint[0] * this.ratio, localPoint[1] * this.ratio];
    var centered_left = -bigPoint[0] + this.current_img_size.width / 2;

    if (centered_left > 0) {
      centered_left = 0;
    }
    else if (centered_left < this.current_img_size.width - this.current_big_img_size.width) {
      centered_left = this.current_img_size.width - this.current_big_img_size.width;
    }

    var centered_top = -bigPoint[1] + this.current_img_size.height / 2;
    if (centered_top > 0) {
      centered_top = 0;
    }
    else if (centered_top < this.current_img_size.height - this.current_big_img_size.height) {
      centered_top = this.current_img_size.height - this.current_big_img_size.height;
    }

    var rect = $(".zoom-rect", this.$el);
    var rect_x = localPoint[0] - rect.width() / 2;
    var rect_y = localPoint[1] - rect.height() / 2;
    rect_x = Math.max(rect_x, 0);
    rect_x = Math.min(rect_x, this.current_img_size.width - rect.outerWidth());
    rect_y = Math.max(rect_y, 0);
    rect_y = Math.min(rect_y, this.current_img_size.height - rect.outerHeight());

    rect.css({
      left: rect_x,
      top: rect_y
    });

    this.current_big_img.css({
      left: centered_left + "px",
      top: centered_top + "px"
    });
  },
  out: function(e) {
    if (!this.container) {
      return;
    }

    if (!this.checkBounds(e.pageX, e.pageY)) {
      this.container.find("img").remove();

      $(".zoom-rect, .pad, .zoom-container", this.$el).remove();

      $(".pad", this.$el).unbind("mousemove");
      $(".pad", this.$el).unbind("mouseout");

      this.$el.trigger("zoom.remove");
      this.inside = false;
    }
  },
  hover: function(e) {
    if (this.inside || this.$el.hasClass("too-small")) {
      return;
    }

    this.inside = true;
    this.current_img = $(e.target);
    this.current_img_size = {
      width: this.current_img.width(),
      height: this.current_img.height()
    };

    var fill_css = {
      position: "absolute",
      top: 0,
      left: 0,
      width: this.current_img_size.width,
      height: this.current_img_size.height,
      zIndex: 199
    };
    var pad = $('<div class="pad">').css(fill_css);
    this.$el.append(pad);

    fill_css.zIndex = 197;
    this.container = $('<div class="zoom-container">').css(fill_css);
    this.$el.append(this.container);


    this.$el.trigger("zoom.add");

    this.loadImg(this.current_img.attr("data-bigimage"), this.imgLoaded);

    this.hoverE = e;
    var that = this;
    $(".pad", this.$el).bind("mouseout", $.proxy(this.out, this)).bind("mousemove", function(e) {
      that.hoverE = e;
    });
  },
  loadImg: function(url, callback) {
    this.container.addClass("loading");

    var img = document.createElement("img");
    var that = this;
    $(img).load(function() {
      callback.call(that, $(img));
    });
    img.src = url;
  },
  imgLoaded: function($img) {
    this.container.removeClass("loading");
    if (!this.inside) {
      return;
    }

    this.container.find("img").remove();
    $img.css("position", "absolute");
    $img.css("max-width", "none");

    this.current_big_img = $img;
    this.container.append($img);

    this.current_big_img_size = {
      width: $img.width(),
      height: $img.height()
    };
    this.ratio = this.current_big_img_size.width / this.current_img_size.width;

    var zoom_rect = $('<div class="zoom-rect">').css({
      width: this.container.width() * this.current_img_size.width / this.current_big_img_size.width,
      height: this.container.height() * this.current_img_size.height / this.current_big_img_size.height,
      position: "absolute",
      zIndex: 198
    });

    if (this.$el.find(".zoom-rect").length === 0) {
      this.$el.append(zoom_rect);
    }

    $(".pad", this.$el).unbind("mousemove").bind("mousemove", $.proxy(this.move, this));
    this.move(this.hoverE);
  },
  // check if point is inside the zoomed image
  checkBounds: function(x, y) {
    if (!this.current_img) {
      return false;
    }
    return (x > this.current_img.offset().left && x < this.current_img.offset().left + this.current_img.width()
        && y > this.current_img.offset().top && y < this.current_img.offset().top + this.current_img.height());
  },

  isMobile: function() {
    // eslint-disable-next-line no-undef,max-len
    return Function.prototype.bind && (('ontouchstart' in window) || window.navigator.msPointerEnabled || window.DocumentTouch && document instanceof DocumentTouch);
  },

  openFullscreen: function() {
    var that = this;
    var val = this.$el.data("fullscreen-image");
    var nr = this.$el.data("fullscreen-id");
    $('#pda-image-fullscreen-bullets div').removeClass('pda-image-fullscreen-bulletActive');

    // Daten sammeln
    var arr = $('.imageFullscreen').map(function() {
      return $(this).data('pda-image-fullscreen');
    });

    $("#pda-image-fullscreen-bullets .nm-icon-circle:not(#bullet_1)").remove();


    // Bullets erzeugen und Klickevent hinzufügen
    var cnt = 0;
    for (var i = 1; i < arr.length; i++) {
      cnt = i + 1;
      $("#bullet_" + i).clone().attr('id', 'bullet_' + cnt).attr('data-index', cnt).insertAfter("#bullet_" + i);
    }

    $('#pda-image-fullscreen-bullets div').on('click', function() {
      $('#pda-image-fullscreen-bullets div').removeClass('pda-image-fullscreen-bulletActive');
      $(this).addClass('pda-image-fullscreen-bulletActive');
      $('#pda-image-fullscreen').attr('src', $('body').find('div.' + $(this).attr('id')).data('pda-image-fullscreen'));

      // show hide arrows on thumbs length
      that.showOrHideFullscreenArrows($(this).data('index'), arr.length);
      $('#pda-image-fullscreen-wrapper').panzoom('reset');
    });

    $('#pda-image-fullscreen-bullets').find('#bullet_' + nr).addClass('pda-image-fullscreen-bulletActive');
    $('#pda-image-fullscreen-container').show();
    $('body').addClass('bodyFullscreenWhite');
    $('.row.detail-view').hide();
    var image = $('#pda-image-fullscreen').attr('src', val).get(0);

    if (this.isMobile()) {

      $('#pda-image-fullscreen-wrapper').panzoom({
        contain: 'invert',
        minScale: 1,
        maxScale: image.naturalWidth / image.clientWidth
      });
    }

    // show hide arrows on thumbs length
    this.showOrHideFullscreenArrows(nr, arr.length);
  },

  closeFullscreen: function() {
    $('#pda-image-fullscreen-container').hide();
    $('body').removeClass('bodyFullscreenWhite');
    $('.row.detail-view').show();
    $('#pda-image-fullscreen').removeAttr('src');
    $("#pda-image-fullscreen-bullets .glyphicon-circle:not(#bullet_1)").remove();
    $(".pad").first().trigger("mouseout");
  },

  prevFullscreen: function() {
    // Daten sammeln
    var arr = $('.imageFullscreen').map(function() {
      return $(this).data('pda-image-fullscreen');
    });

    for (var i = arr.length; i > 0; i--) {
      if ($('#pda-image-fullscreen').attr('src') === arr[i]) {
        $('#pda-image-fullscreen').attr('src', arr[--i]);
        var current = i;
        if (i >= 0) {
          current = i + 1;
        }

        // show hide arrows on thumbs length
        Zoom.prototype.showOrHideFullscreenArrows(current, arr.length);

        $('#pda-image-fullscreen-bullets div').removeClass('pda-image-fullscreen-bulletActive');
        $('#pda-image-fullscreen-bullets').find('#bullet_' + current).addClass('pda-image-fullscreen-bulletActive');
      }
    }
    $('#pda-image-fullscreen-wrapper').panzoom('reset');
    window.getSelection().removeAllRanges();
  },

  nextFullscreen: function() {
    // Daten sammeln
    var arr = $('.imageFullscreen').map(function() {
      return $(this).data('pda-image-fullscreen');
    });

    for (var i = 0; i < arr.length; i++) {
      if ($('#pda-image-fullscreen').attr('src') === arr[i]) {
        $('#pda-image-fullscreen').attr('src', arr[++i]);
        var current = i;
        if (i < arr.length) {
          current = i + 1;
        }

        // show hide arrows on thumbs length
        Zoom.prototype.showOrHideFullscreenArrows(current, arr.length);

        $('#pda-image-fullscreen-bullets div').removeClass('pda-image-fullscreen-bulletActive');
        $('#pda-image-fullscreen-bullets').find('#bullet_' + current).addClass('pda-image-fullscreen-bulletActive');
      }
    }
    $('#pda-image-fullscreen-wrapper').panzoom('reset');
    window.getSelection().removeAllRanges();
  },

  showOrHideFullscreenArrows: function(index, numberOfImages) {
    var showLeft = numberOfImages > 1 && index !== 1;
    var showRight = numberOfImages > 1 && index < numberOfImages;
    $('#pda-image-fullscreen-right').toggle(showRight);
    $('#pda-image-fullscreen-left').toggle(showLeft);
  }
};

var isNotZoomed = function() {
  return $('#pda-image-fullscreen-wrapper').css('transform') === 'matrix(1, 0, 0, 1, 0, 0)';
};

$.fn.zoom = function(options) {
  options = Object.assign(defaults, options);

  var matched = $(this);

  matched.each(function() {
    var zoom = new Zoom($(this), options);
    $(this).data("Zoom", zoom);
  });

  $("#pda-image-fullscreen-close").off("click").click(Zoom.prototype.closeFullscreen);
  $("#pda-image-fullscreen-left").off("click").click(Zoom.prototype.prevFullscreen);
  $("#pda-image-fullscreen-right").off("click").click(Zoom.prototype.nextFullscreen);

  // jQuery Mobile Swipe funktioniert mit ES6 nicht, deswegen wurde bei den IIFE das 'this' auf 'this || window' geändert
  // Vergleiche: http://richardsumilang.com/programming/javascript/jquery/using-jquery-mobile-in-a-ecmascript-6-project/
  $.event.special.swipe.horizontalDistanceThreshold = 100;
  $("#pda-image-fullscreen-wrapper").off("swiperight").on("swiperight", function() {
    if (isNotZoomed()) {
      Zoom.prototype.prevFullscreen();
    }
  });
  $("#pda-image-fullscreen-wrapper").off("swipeleft").on("swipeleft", function() {
    if (isNotZoomed()) {
      Zoom.prototype.nextFullscreen();
    }
  });

  $(document).off("keyup").keyup(function(e) {
    var fullscreen = $("#pda-image-fullscreen-container");
    if (fullscreen.is(":visible")) {
      switch(e.keyCode) {
        case 27: // escape
          Zoom.prototype.closeFullscreen();
          break;
        case 37: // left
          Zoom.prototype.prevFullscreen();
          break;
        case 39: // right
          Zoom.prototype.nextFullscreen();
          break;
      }
    }
  });

  return matched;
};
