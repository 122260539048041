/* eslint-disable no-console */

import $ from 'jquery';

/**
 * Every plugins javascript has to register in the system to guarantee sound
 * loading of its dependencies:
 *
 * window._is_plugin = window._is_plugin || function() { (_is_plugin.q = _is_plugin.q || []).push(arguments) };
 *
 * _is_plugin("jquery", function($) { ... });
 *
 */

class PluginLoader {

  constructor(env) {
    this.resolved = env;
  }

  // A convenience function for define. The last parameter has to be a function.
  enqueue(...args) {
    const fn = args.pop();
    this.define(args, fn);
  }

  /* Registers a function with its dependencies. The function will be executed
   * when all dependencies have been resolved.
   */
  define(deps, fn) {
    const resolvedDeps = deps.map(dep => this.resolve(dep));
    Promise.all(resolvedDeps)
      .then(args => fn(...args));
  }

  resolve(dep) {
    // Initiate script loading
    const load = x => this.loadDependency(x);

    // Get dependency from resolver cache.
    const lookup = x => this.resolved[x];

    // Write dependency and its promise to the cache.
    const store = (dep, prom) => this.resolved[dep] = prom;

    // Logs to console.log if present.
    const log = s => console && console.log && console.log(s);

    const resolved = lookup(dep);
    if (typeof resolved === 'undefined') {
      /* If dependency hasn't been resolved before,
       * initiate the script loading and store the
       * in the cache. Hence subsequent plugins requiring
       * the same dependency benefit from sharing.
       */
      const loading = load(dep)
        .catch(err => log("Plugin dependency failed to load: " + dep + " Reason: " + err));

      store(dep, loading);
      return loading;
    } else {
      return resolved;
    }
  }

  loadDependency(dep) {
    const prom = new Promise(function(resolve, reject) {
      $.ajax({
        url: dep,
        dataType: 'script',
        success: function() {
          resolve();
        },
        error: function(_, status, err) {
          reject(err);
        }
      });
    });
    return prom;
  }

}

// An initial environment for the PluginLoader.
const env = {
  'jquery': Promise.resolve($)
};
const loader = new PluginLoader(env);

// Make sure this _is_plugin namespace is present
// eslint-disable-next-line no-underscore-dangle
window._is_plugin = window._is_plugin ||
function() {
  // eslint-disable-next-line no-undef
  (_is_plugin.q = _is_plugin.q || []).push(arguments);
};
// eslint-disable-next-line no-underscore-dangle
const q = window._is_plugin.q || [];

// Replace queueing loader with real loader
// eslint-disable-next-line no-underscore-dangle
window._is_plugin = loader.enqueue.bind(loader);
window.define = loader.define.bind(loader);
q.forEach(plugin => loader.enqueue(...plugin));

export default loader;
