import $ from 'jquery';
import ProductListFilters from '../components/productlist_filters';
import Pubsub from 'pubsub';
import Offcanvas from '../components/offcanvas';
import LazyScroll from '../components/lazyscroll';
// import 'bootstrap';

export default function Product() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.resxs, this));
  this.offcanvas = new Offcanvas();
  this.filters = new ProductListFilters();
}

Product.prototype = {
  attach: function(msg, element) {

    this.lazyScroll = new LazyScroll(".prd-grid-item");

    //Form Toggle
    var toggleBtn = $('.page-prd-list [data-toggle=offcanvas]');
    var that = this;

    toggleBtn.off("click").on("click", function(e) {
      e.preventDefault();
      e.stopPropagation();

      that.offcanvas.toggle();
    });

    $('input:radio').click(function() {
      $('label[for="' + $(this).attr('id') + '"]').toggleClass('list-active');
    });

    //Close
    $(".prd-filter .close-btn", element).off("click").on("click", function(e) {
      e.preventDefault();
      that.offcanvas.hide();
    });

    if (that.isxs) {
      that.offcanvas.switchIt(that.isxs, true);
    } //Double from offcanvas, but needed for speed..
  }, //attach
  resxs: function(message, on_off) {
    if ((message === "res.xs" || message === "res.sm") && on_off) {
      this.isxs = on_off;
      this.offcanvas.switchIt(on_off);
    } else if ((message === "res.md" || message === "res.lg") && on_off) {
      this.isxs = false;
      this.offcanvas.switchIt(false);
    }
    //Re-Setup Swipes
    /*if (on_off) {
      $(".prd-grid-item .swipe").each(function() {
        if ($(this).data("Swipe")) {
          $(this).data("Swipe").setup();
        }
      });
    }*/
  },
  enableRange: function(enable) {
    $("#from_price, #to_price").prop("disabled", !enable);
  }
};
