import $ from 'jquery';
import Swiper from 'swiper';

export default function SliderInitializer() {
}

SliderInitializer.prototype = {
  defaultSliderConfigs: [
    {
      selector: ".slider-brands",
      config: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: 2,
        slidesPerGroup: 1,
        loop: false,
        breakpoints: {
          1200: { slidesPerView: 8 },
          1025: { slidesPerView: 5 },
          768: { slidesPerView: 3 }
        }
      }
    },
    {
      selector: ".slider-recommendation",
      config: {
        navigation: {
          nextEl: ".right.carousel-control",
          prevEl: ".left.carousel-control",
          disabledClass: "inactive"
        },
        pagination: {
          el: '.carousel-indicators', //pagination
          paginationElement: 'li',
          bulletClass: 'indicator',
          bulletActiveClass: 'active',
          clickable: true
        },
        preloadImages: false, // needed to enable "lazyLoading"
        lazy: {
          enabled: true,
          loadPrevNext: true,
          loadingClass: 'img-prefill',
          loadedClass: 'loaded',
          preloaderClass: 'preload-container'
        },
        slidesPerView: 2,
        slidesPerGroup: 2,
        breakpoints: {
          1025: {
            slidesPerView: 4,
            slidesPerGroup: 4
          },
          768: {
            slidesPerView:3,
            slidesPerGroup: 3
          },
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2
          }
        }
      }
    },
    {
      selector: ".slider-teaser",
      config: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationElement: 'span'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        loopedSlides: 2,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 600
      }
    }
  ],
  sliderConfigs: {
    productSliderConfig: {
      selector: ".slider-prd-image",
      config: {
        navigation: {
          nextEl: ".right.carousel-control",
          prevEl: ".left.carousel-control",
          disabledClass: 'inactive'
        },
        loop: true,
        slidesPerView: 1, // needed to enable "lazyLoadingInPrevNext"
        preloadImages: false, // needed to enable "lazyLoading"
        lazy: {
          enabled: true,
          loadPrevNext: true,
          loadingClass: "img-prefill",
          loadedClass: "loaded",
          preloaderClass: "preload-container"
        }
      }
    }
  },

  multiInit: function(selectorName) {
    let sliderConfigs = this.defaultSliderConfigs;
    let sliders = [];
    let domSelector = selectorName ? document.querySelector(selectorName) : null;
    let element = domSelector || document;
    let that = this;

    $.map(sliderConfigs, function(sliderConfig) {
      if ($(sliderConfig.selector).length > 0) {
        sliders.push(new Swiper (sliderConfig.selector, sliderConfig.config));
      }
    });
    that.adaptMultiLinkSliderTeaserPagination(element);

    return sliders;
  },

  singleInit: function (selector, sliderConfig) {
    return new Swiper (selector, sliderConfig);
  },

  // Taken from MultiLinkSliderTeaser JSP
  adaptMultiLinkSliderTeaserPagination: function (element) {
    const paginationHeight = 40;
    let pagination = element.querySelectorAll(".multi-link-slider-teaser .swiper-pagination");
    pagination.forEach(function (element, index) {
       let referenceHeight = element.getAttribute("data-reference-height");
       element.style.top = referenceHeight - paginationHeight + "px";
    });
  }
};
