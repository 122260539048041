// Everything that should happen on every page
import $ from 'jquery';
import Pubsub from 'pubsub';
import Affiliate from './affiliate';
import Loading from '../components/loading';
import config from '../config/config';
import 'suggest';

export default function General() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.resxs, this));

  this.loading = new Loading();
}

General.prototype = {
  attach: function(msg, element) {
    var that = this;

    $(".text-tooltip").popover({
      placement: "top",
      html: true,
      trigger: "hover"
    }).off("shown.bs.popover.text").on("shown.bs.popover.text", function() {
      var popover = $(this).next();
      $("body > *").on("click.popclose", function(e) {
        $("body > *").off("click.popclose");
        popover.popover("hide");
      });
      //Move 5px up
      popover.css({
        top: parseInt($(this).next().css("top"), 10) - 5 + "px"
      });
    });

    //Add classes to accordions
    var addClasses = function(e) {
      $(this).parents(".panel").find(".panel-collapse").each(function() {
        if ($(this).hasClass("in")) {
          $(this).prev().addClass("panel-in");
        } else {
          $(this).prev().removeClass("panel-in");
        }
      });
      if (e.type === "show") {
        $(this).prev().addClass("panel-in");
      }
    };
    $('.collapse').off('show.bs.collapse.classes hidden.bs.collapse.classes')
        .on('show.bs.collapse.classes hidden.bs.collapse.classes', addClasses);
    $('.collapse.in').trigger('show.bs.collapse.classes');


    if (element === document) {
      new Affiliate(); // eslint-disable-line no-new

      this.openHash();
      window.onhashchange = this.openHash;
      //Special case if hash stays same
      $("a[href^='#']").click(function(e) {
        if ($(this).attr("href") === window.location.hash) {
          that.openHash();
        }
      });
    }

    //Hide alerts, not remove
    $(".alert .close").off("click").on("click", function(e) {
      e.preventDefault();
      $(this).parent().hide();
    });

    //Modals, several fixes
    $('.modal').off('show.bs.modal').on('show.bs.modal', function() {

      var measureScrollBar = function() {
        var scrollDiv = document.createElement('div');
        scrollDiv.style.cssText = 'width: 1px;height: 1px;overflow: scroll;position: absolute;top: -9999px;';
        document.body.appendChild(scrollDiv);
        var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return scrollbarWidth;
      };
      if (navigator.userAgent.match(/iPhone|iPad|iPod|Windows Phone/i)) {
        var scrollTop = $(window).scrollTop();
        $(this).css({
          position: 'absolute',
          marginTop: scrollTop + 'px',
          bottom: 'auto'
        });

        setTimeout(function() {
          $('.modal-backdrop').css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: $(document).height() + 'px'
          });
        }, 0);
        $(".modal .scroll-top").on("click", function (e) {
          e.preventDefault();
          $("body, html").animate({scrollTop: scrollTop}, 1000);
        });
      } else {
        $(".modal .scroll-top").on("click", function (e) {
          e.preventDefault();
          $(".modal").animate({scrollTop: 0}, 1000);
        });
      }
      $("html").addClass("modal-open");

      if (this.clientHeight <= window.innerHeight) {
        return;
      }
      var scrollbarWidth = measureScrollBar();
      if (scrollbarWidth) {
        $(document.body).css('padding-right', scrollbarWidth);
      }
    }).off('hidden.bs.modal').on('hidden.bs.modal', function() {
      $("#page .twerked").removeClass('twerked').prop("disabled", false);
      $("html").removeClass("modal-open");
      $(document.body).css('padding-right', 0);
    });


    // suggest
    function escapeRegExChars(value) {
      return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }
    $('.main-search .form-control').autocomplete({
      maxHeight: 600,
      minLength: 1,
      serviceUrl: $('.main-search .form-control').attr('data-js-suggest'),
      paramName: 'q',
      containerClass: 'suggest',
      transformResult: function(response) {
        var jsonResponse = $.parseJSON(response);
        return {
          // eslint-disable-next-line max-len
          suggestions: $.map($.merge($.merge($.merge($.merge(jsonResponse.suggestions.topLevelCategorySuggestions, jsonResponse.suggestions.categorySuggestions), jsonResponse.suggestions.brandSuggestions), jsonResponse.suggestions.topSearchSuggestions), jsonResponse.suggestions.productSuggestions), function(dataItem) {
            return {
              value: dataItem.value,
              data: dataItem.data,
              title: dataItem.title,
              categoryName: dataItem.categoryName,
              link: dataItem.link,
              imageLink: dataItem.productImagePath,
              price: dataItem.price,
              availability: dataItem.availability,
              isBrand: dataItem.brand,
              isCategory: dataItem.category,
              isProduct: dataItem.product,
              isFromPrice: dataItem.fromPrice
            };
          })
        };
      },
      // eslint-disable-next-line complexity
      formatResult: function(suggestion, currentValue) {
        var pattern = '(' + escapeRegExChars(currentValue) + ')';
        var result = "";
        if(suggestion.isProduct) {
          var suggestionAvailability = suggestion.availability
              ? config.translations['searchbox.suggestion.availability.available']
              : config.translations['searchbox.suggestion.availability.notAvailable'];
          var availablityTextClass = suggestion.availability
              ? "productSuggestionAvailable"
              : "productSuggestionNotAvailable";
          result += '<div class="row productSuggestion">';
          result += '<div class="col-xs-2 no-pad"><img src="' + suggestion.imageLink + '"></div>';
          result += '<div class="col-xs-5">'
              + suggestion.value.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>') + '</div>';
          result += '<div class="col-xs-5 suggestionInfoText no-pad">' + '<div>';
          if (suggestion.isFromPrice) {
            result += config.translations['searchbox.suggestion.product.fromprice'] + ' ';
          }
          result += suggestion.price.replace(".", ",").replace("EUR", "€") + '</div>';
          result += '<div class="' + availablityTextClass + '">' + suggestionAvailability + '</div>';
          result += '</div>';
          result += '</div>';
        }else if(!suggestion.isBrand && !suggestion.categoryName || suggestion.isCategory) {
          result += suggestion.value.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
        }
        if(suggestion.isCategory){
          var suggCategory = suggestion.categoryName.split('-');
          result += '<span class="brand-name"> in ' + suggCategory[0].charAt(0).toLocaleUpperCase()
              + suggCategory[0].slice(1) + '</span>';
        }
        if (!suggestion.data || ($.trim(suggestion.data) === "")) {
          return result;
        }
        if (suggestion.data && !suggestion.categoryName) {
          result += " " + suggestion.data;
        }
        return result;
      },
      onSelect: function(suggestion) {
        if (!suggestion.link || ($.trim(suggestion.link) === "")) {
          if (!suggestion.categoryName || ($.trim(suggestion.categoryName) === "")) {
            $('input[name="qc"]').remove();
          } else {
            $('input[name="qc"]').val(suggestion.categoryName);
          }
          $('#search_id_form').submit();
        } else {
          window.location.href = suggestion.link;
        }
      },
      // eslint-disable-next-line complexity
      headline: function(suggestion) {
        var result = "";
        var wrapperClass = "";
        var suggTitle = "";
        if(suggestion.isProduct){
          wrapperClass = "product";
          suggTitle = config.translations['searchbox.suggestion.product.title'];
        } else if(suggestion.isCategory) {
          wrapperClass = "category";
          suggTitle = config.translations['searchbox.suggestion.category.title'];
        }
        if ((suggestion.isCategory === true || suggestion.isProduct === true)
            && suggestion.title && ($.trim(suggestion.title) !== "")) {
          result = '<div class="header-divider-title" '
              + ('id = "js-header-divider-' + wrapperClass + '"') + '>' + suggTitle + '</div>';
        } else if (suggestion.title && ($.trim(suggestion.title) !== "")
            && !suggestion.categoryName && !suggestion.isBrand) {
          result = '<div class="header-divider-title" ' + ('id = "js-header-divider-topSearch"') + '>'
            + config.translations['searchbox.suggestion.topSearch.title'] + '</div>';
        }
        return result;
      },
      resultFinishedCb:function (currentValue) {
        //console.log('finished: ' + $('#js-header-divider-product ~ div').length);
        // eslint-disable-next-line max-len
        $('#js-header-divider-product, #js-header-divider-product ~ .autocomplete-suggestion').wrapAll( '<div class="col-sm-8 col-xs-12 no-pad" id="js-rightCol"/>');
        // eslint-disable-next-line max-len
        $('#js-header-divider-topSearch, #js-header-divider-topSearch ~ .autocomplete-suggestion, #js-header-divider-category, #js-header-divider-category ~ .autocomplete-suggestion').wrapAll( '<div class="col-sm-12 col-xs-12 no-pad" id="js-leftCol" />');
        $('#js-leftCol, #js-rightCol').wrapAll( '<div class="row no-pad" id="searchSuggRow"/>');
        if($('#js-rightCol').siblings().length === 0){
          $('#js-rightCol').attr('class','col-xs-12 no-pad');
        }
        var searchUrl = window.location.protocol + "//" + window.location.host
            + $('#search_id_form').attr('action') + "?q=" + currentValue;
        var searchText = config.translations['searchbox.suggestion.search.link']
            .replace("{0}", "<strong>" + currentValue + "</strong>");
        $('#js-leftCol, #js-rightCol')
            .append("<div class='autocomplete-suggestion'><a href='" + searchUrl + "'>" + searchText + "</a></div>");
      }
    });

    // To top button
    // Show or hide the sticky footer button
    $(window).off("scroll.gotop").on("scroll.gotop", function() {
      if ($(this).scrollTop() > 400) {
        $('.go-top').addClass('show-button-to-top');
      } else {
        $('.go-top').removeClass('show-button-to-top');
      }
    });
    // Animate the scroll to top
    $('.go-top').off("click").on("click", function(event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: 0
      }, 300);
    });

    let searchHeader = document.getElementById("js-stickyHeader");
    $(window).on("resize scroll", function() {
      if (searchHeader !== undefined && searchHeader) {
        let hdrHeaderHeight = document.getElementsByClassName("hdr-header")[0].clientHeight;
        if (window.pageYOffset > 0) {
          searchHeader.classList.add("sticky");
          $("#js-stickyHeader").css("top", hdrHeaderHeight + "px");
        } else {
          searchHeader.classList.remove("sticky");
        }
      }
    });

  /*
   * Replace all SVG images with inline SVG, which has 'icn-icon' class
   */
    $('img.icn-icon').each(function(){
      var $img = $(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');

      $.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg');

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
          $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

      }, 'xml');

    });


    // init modals
    that.initModalButtons();
  },
  resxs: function(msg, onoff) {
    var search_form;
    if (msg === "res.xs") {
      if (onoff) {
        search_form = $("header .search-form").html();
        if (search_form !== "") {
          $(".mobile-search .collapse-wrapper").html(search_form);
          $("header .search-form").empty();
          Pubsub.publish("core.attach", $(".mobile-search .collapse-wrapper"));
          $(".mobile-search .glyphicon").removeClass("glyphicon-search").addClass('glyphicon-arrow-right');
        }
      } else {
        search_form = $(".mobile-search .collapse-wrapper").html();
        if (search_form !== "") {
          $("header .search-form").html(search_form);
          $(".mobile-search .collapse-wrapper").empty();
          Pubsub.publish("core.attach", $("header .search-form"));
          $("header .search-form .glyphicon").removeClass("glyphicon-arrow-right").addClass('glyphicon-search');
        }
      }
      $('.suggest').remove();
    }
  },
  openHash: function() {
    var regex = /(_[0-9]{0,})$/;
    if (document.location.hash && regex.test(document.location.hash)) {
      var matches = document.location.hash.match(regex);
      var indexMatches = matches[0];

      $(".panel-heading").not(".panel-in").find("a[href='#collapse" + indexMatches + "']").click();
    }
  },

  initModalButtons: function () {
    let that = this;
    //open in modal
    const linkClasses = ".in-modal";

    $(linkClasses).off("click").on("click touchstart", function (e) {
      let endpoint = undefined;
      e.preventDefault();
      e.stopPropagation();

      let title = ($(this).attr('data-title')) ? $(this).attr('data-title') : "";
      let hrefAttr = ($(this).attr('data-href')) ? "data-href" : "href";
      endpoint = $(this).attr(hrefAttr);

      that.loading.load();
      const modalContainer = $("#the-modal");
      modalContainer.empty().load(endpoint, function (data) {
        $("#modal-title").text(title);
        modalContainer.modal();
        modalContainer.on('shown.bs.modal');
        that.loading.stop();
      });
    });
  }
};

