/**
 * Kümmert sich um die Behandlung der Filter und Sortierung auf der Produktliste. Dazu gehört auch das Neuladen der Daten.
 */
//noinspection JSHint
import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';
import History from 'history';
import SliderInitializer from '../utils/slider-initializer';

/**
 * Erzeugt eine Funktion die die Daten neu lädt wenn sich die History ändert. Die History ändert sich bspw. wenn man
 * einen Filter auswählt oder wenn man von einer IE Url einspringt, welche eine # enthält.
 */
function createLoadDataOnHistoryChangeFunction(self) {
  return function () {
    const selectorName = ".prd-list-part";
    // Log the State
    let state = History.getState(); // Note: We are using History.getState() instead of event.state
    let url = state.url;
    let sign = url.includes("?") ? "&" : "?";
    let ajaxUrl = url + sign + "ajax="; // marker Parameter hinzufügen, dass wir nur den AJAX Content austauschen wollen

    $.get(ajaxUrl, function (data) {
      self.loading.stop();
      let $data = $(data);
      Pubsub.publish("core.preattach", $data); // restore accordion positions
      setTimeout(function () {
        let listSelector = $(selectorName);
        listSelector.replaceWith($data);
        if (listSelector.length === 1) {
          self.sliderInitializer.multiInit(selectorName);
        }
        Pubsub.publish("core.attach", $data);
      }, 1); //Trick accordion animation
    }).fail(function () {
      self.loading.stop();
    });
  };
}

export default function ProductListFilters() {
  Pubsub.subscribe('core.preattach', $.proxy(this.preattach, this));
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.in_ids = [];
  this.loading = new Loading();
  this.sliderInitializer = new SliderInitializer();

  // Bind to State Change,  Note: We are using statechange instead of popstate
  History.Adapter.bind(window, 'statechange', createLoadDataOnHistoryChangeFunction(this));
}

//brand filter autocomplete
function initBrandSuggest() {
  $('.js-filterBrandList').each(function () {
    let $brandList = $(this).find('.js-checkbox');
    if ($brandList.length > 0) {
      let brands = $.map($brandList, function (item) {
        return {
          value: $(item).attr('data-value'),
          data: $(item).attr('data-index')
        };
      });
      let $wrapper = $(this).find('.js-autocomplete-brand-wrapper');
      $(this).find('.js-brand-suggest').autocomplete({
        lookup: brands,
        containerClass: 'autocomplete-wrapper',
        appendTo: $wrapper,
        onSelect: function (suggestion) {
          let $filterbox = $(this).closest('.js-filterBrandList');
          let $target = $filterbox.find('.js-checkbox[data-index=' + suggestion.data + ']');
          if (!$target.hasClass('checked')) {
            $target.click();
          }
        }
      });
    }
  });
}

ProductListFilters.prototype.preattach = function (msg, element) {
  $.each(this.in_ids, function () { //restore open accordion tabs
    element.find(".prd-filter-list #" + this).removeClass("collapse").addClass("in");
  });
};

function resetInputs($inputs) {
  let refresh = false;
  $inputs.each(function () {
    if ($(this).prop("checked")) {
      $(this).prop("checked", false);
      refresh = true;
    }
    if ($(this).is(":text") && $(this).val() !== $(this).attr("data-original")) {
      $(this).val($(this).attr("data-original"));
      refresh = true;
    }
  });
  if (refresh) {
    $inputs.first().trigger("change");
  }
}

ProductListFilters.prototype.attach = function (msg, element) {
  let that = this;
  let previousScreenWidth = $(window).width();

  $("#from_price, #to_price").on("click", function () {
    $("input[value=range]").attr("checked", "checked");
  });

  $(".color-thumb").tooltip();

  const mq = window.matchMedia("(max-width: 768px)");
  const range = document.querySelectorAll('.range-slider input');
  const progress = document.querySelector('.range-slider .progress');
  let gap = 1;
  const inputValue = document.querySelectorAll('.numberVal input');

  range.forEach(input => {
    input.addEventListener('input', event => {
      let minRange = parseInt(range[0].value),
          maxRange = parseInt(range[1].value);
      if ((maxRange - minRange) < gap) {
        if (event.target.className === "range-min") {
          range[0].value = maxRange - gap;
        } else {
          range[1].value = minRange + gap;
        }
      } else {
        progress.style.left = (minRange / range[0].max) * 100 + '%';
        progress.style.right = 100 - (maxRange / range[1].max) * 100 + '%';
        inputValue[0].value = minRange;
        inputValue[1].value = maxRange;
      }
    });
  });

  function adjustFilterForMobile() {
    if (mq.matches) {
      $("form#filter-form button[data-value='Filter']").addClass("collapsed");
      $("form#filter-form ul.js-toggle").addClass("collapse").removeClass("in");
    } else {
      $("form#filter-form button[data-value='Filter']").removeClass("collapsed");
      $("form#filter-form ul.js-toggle").addClass("in");
    }
  }

  adjustFilterForMobile();

  function adjustNavigationForMobile() {
    if (mq.matches) {
      $("#item-sort").appendTo($("#mobile-filter-dropdown-product_list_inner"));
      $("#product-category").addClass("collapsed");
      $("#category-dropdown, #filter-category-dropdown, #filter-dropdown, .collapse.in").removeClass("in");
    } else {
      $("#item-sort").appendTo($("#desktop-filter-dropdown-product_list_inner"));
      $("#product-category").removeClass("collapsed");
      $("#category-dropdown, #filter-category-dropdown, #filter-dropdown, .collapse.in").addClass("in");
    }
  }

  adjustNavigationForMobile();

  $(window).resize(function () {
    if (previousScreenWidth !== $(window).width()) {
      previousScreenWidth = $(window).width();
      adjustFilterForMobile();
      adjustNavigationForMobile();
    }
  });

  //Filter changes
  $("form#filter-form input, #item-count", element).off("change").on("change", $.proxy(this.handleFilters, this));
  $("#item-sort", element).off("change").on("change", $.proxy(this.reloadSort, this));
  // Handle single filter
  $("form#filter-form .current-filter", element).off("click").on("click", $.proxy(this.handleSingleFilter, this));
  //Reset all filters
  $("form#filter-form .filter-reset", element).off("click").on("click", $.proxy(this.reloadWithoutFilters, this));

  // Reset single filter
  $("form#filter-form .current-filter-reset", element).off("click").on("click", function () {
    let inputName = $(this).attr('data-input-name');
    if (typeof inputName !== "undefined") {
      resetInputs($("form#filter-form input[name='" + inputName + "']", element));
    }
  });

  let bs_events = "shown.bs.collapse hidden.bs.collapse";
  $(".prd-filter").off(bs_events).on(bs_events, function () {
    that.in_ids = []; //save open accordion tabs
    $(".prd-filter-list .in").each(function () {
      that.in_ids.push($(this).attr("id"));
    });
  });
  initBrandSuggest();
};

ProductListFilters.prototype.handleFilters = function (e) {
  const mq = window.matchMedia("(max-width: 768px)");
  if (!mq.matches) {
    this.reload(e);
  }
};

// eslint-disable-next-line complexity
ProductListFilters.prototype.handleSingleFilter = function (e) {
  let that = this;
  let inputName = $(e.target).data('input-name');
  if (typeof inputName !== "undefined") {
    let $inputs = $("form#filter-form input[name='" + inputName + "']:checked");
    let paramsObj = {};
    paramsObj = that.getQueryParamsAsObject();
    if (paramsObj[inputName] !== undefined) {
      paramsObj[inputName] = [];
    }
    if ($inputs.size() > 0) {
      $inputs.each(function () {
        (paramsObj[inputName] = paramsObj[inputName] || []).push($(this).val());
      });
      if (inputName === "filter.PRICE") {
        paramsObj.price_range_changed = !!$("input[value=range]").is(":checked");
        // eslint-disable-next-line max-depth
        if (paramsObj.price_range_changed) {
          paramsObj['filter.from_PRICE'] = $("#from_price").val();
          paramsObj['filter.to_PRICE'] = $("#to_price").val();
          paramsObj.price_range_changed = true;
        }
      }
    }
    let queryString = $.param(paramsObj).replaceAll("%5B%5D", "");
    queryString = decodeURIComponent(queryString);
    let filter_form = $("form#filter-form");
    let url = filter_form.attr("action") + "?" + queryString;
    that.reloadUrl(url);
  }
};

ProductListFilters.prototype.getQueryParamsAsObject = function () {
  let params = {};
  if (window.location.search) {
    window.location.search.substr(1).split("&").forEach(function (item) {
      let s = item.split("="),
          k = s[0],
          v = s[1] && decodeURIComponent(s[1]);
      (params[k] = params[k] || []).push(v);
    });
  }
  return params;
};

ProductListFilters.prototype.reloadSort = function (e) {
  let $element = $(e.target);
  let category = $element.data('category'); //eslint-disable-line no-unused-vars
  let action = $element.data('action'); //eslint-disable-line no-unused-vars
  action = action !== undefined ? action.replace('~~PLACEHOLDER~~', $element.find('option:selected').text()) : '';
  let value = $element.data('value'); //eslint-disable-line no-unused-vars
  this.reload(e);
};

ProductListFilters.prototype.reload = function (e) {
  let filter_form = $("form#filter-form");
  let that = this;

  if ($(e.target).is("[value=range]")) {
    this.enableRange(true);
  } else {
    let price_changed = !!($(e.target).is('#from_price') || $(e.target).is('#to_price'));
    let sortValue = $("#item-sort").children("option:selected").val();
    let url = filter_form.attr("action");
    let sign = url.includes("?") ? "&" : "?";
    url += sign + filter_form.serialize() + "&price_range_changed=" + price_changed;
    if (sortValue) {
      url += "&sortBy=" + sortValue;
    }
    that.reloadUrl(url);
  }
};

ProductListFilters.prototype.reloadWithoutFilters = function () {
  let that = this;
  let filter_form = $("form#filter-form");
  let url = filter_form.attr("action");
  let sortValue = $("#item-sort").children("option:selected").val();
  if (sortValue) {
    url += "?sortBy=" + sortValue;
  }
  let currentUrl = window.location.pathname + window.location.search;
  if (url !== currentUrl) {
    that.reloadUrl(url);
  }
};

ProductListFilters.prototype.reloadUrl = function (url) {
  let that = this;
  that.loading.load();
  History.pushState({}, document.title, url);
};
