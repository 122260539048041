import $ from "jquery";

export default function MenuJson() {
}

MenuJson.prototype = {
    init: function(){
    $(document).ready(function () {
      $(function () {
        // burger menu items
        $(".mnu-list__btn-sublist").click(function () {
          var isActive = $(this).closest("li").hasClass("mnu-sublist-active");

          // inactivate all
          $(".mnu-sublist-active").toggleClass("mnu-sublist-active");

          // active current
          if (!isActive) {
            var activeCssClass = "mnu-sublist-active";
            $(this).closest("li").toggleClass(activeCssClass);
          }
        });
      });
    });
  }
};
