import $ from 'jquery';

export default function ModalFns() {
}

ModalFns.prototype = {
  scrollToTop: function() {
    if (this.modal.css("position") === "absolute") {
      $("html, body").animate({
        scrollTop: this.modal.offset().top
      }, 300);
    } else {
      this.modal.animate({
        scrollTop: 0
      }, 300);
    }
  }
};
