import $ from 'jquery';

export default function Html5Validation() {
  // body...
}

Html5Validation.prototype = {
  showValidationErrorMessage: function(element, message) {
    var errorField = $(element).closest('.validation-group').find('.form-error');

    if (message) {
      errorField.show();
      errorField.find('.msg').html(message);
    }
  },
  // eslint-disable-next-line complexity
  validateField: function(element) {
    var that = this;
    var inputField = element.validity;
    var inputFieldIsVisible = $(element).is(":visible");
    var matchingElement = $(element).data("validation-matching-field");
    var hasMatchingError = false;
    if (matchingElement !== undefined) {
      var matchingElementValue = $("#" + matchingElement).val();
      hasMatchingError = !(matchingElementValue === $(element).val());
    }
    if ((!inputField.valid || hasMatchingError) && inputFieldIsVisible) {
      if (hasMatchingError) {
        var $matchingElement = $("#" + matchingElement);
        that.showValidationErrorMessage(element, $(element).data("error-nomatch"));
        that.showValidationErrorMessage($matchingElement[0], $matchingElement.data("error-nomatch"));
      } else if (inputField.valueMissing) {
        that.showValidationErrorMessage(element, $(element).data("error-valuemissing"));
      } else if (inputField.typeMismatch) {
        that.showValidationErrorMessage(element, $(element).data("error-typemismatch"));
      } else if (inputField.patternMismatch) {
        that.showValidationErrorMessage(element, $(element).data("error-patternmismatch"));
      } else if (inputField.tooLong) {
        that.showValidationErrorMessage(element, $(element).data("error-toolong"));
      } else if (inputField.tooShort) {
        that.showValidationErrorMessage(element, $(element).data("error-tooshort"));
      } else if (inputField.rangeUnderflow) {
        that.showValidationErrorMessage(element, $(element).data("error-rangeunderflow"));
      } else if (inputField.rangeOverflow) {
        that.showValidationErrorMessage(element, $(element).data("error-rangeoverflow"));
      } else if (inputField.stepMismatch) {
        that.showValidationErrorMessage(element, $(element).data("error-stepmismatch"));
      } else if (inputField.badInput) {
        that.showValidationErrorMessage(element, $(element).data("error-badinput"));
      } else if (inputField.customError) {
        that.showValidationErrorMessage(element, $(element).data("error-customerror"));
      }
    } else {
      $(element).removeClass('has-error');
      $(element).closest('.validation-group').find('.form-error').hide();
      if (matchingElement !== undefined) {
        $("#" + matchingElement).closest('.validation-group').find('.form-error').hide();
      }
      return true;
    }

    $(element).addClass('has-error');
    return false;
  },
  addValidationHandler: function() {
    var that = this;
    $("form.validate-form input, form.validate-form select, form.validate-form textarea").each(function() {
      // eslint-disable-next-line consistent-this
      var that2 = this;
      $(this).blur(function() {
        that.validateField(that2);
      });
    });
  },
  validateOnSubmit: function() {
    var that = this;
    $("form.validate-form").submit(function(e) {
      var noError = true;
      $(this).find("input, select").each(function() {
        var fieldHasNoError = that.validateField(this);
        noError = noError && fieldHasNoError;
      });

      $("input[form='" + this.id + "']").each(function () {
        var fieldHasNoError = that.validateField(this);
        noError = noError && fieldHasNoError;
      });

      if (!noError) {
        e.preventDefault();
        if ($(this).data("submit-element") !== undefined) {
          var submitButton = $(this).data("submit-element");
          if (submitButton !== undefined) {
            $(submitButton).removeAttr("disabled");
          }
        }
      }
    });
  }
};
